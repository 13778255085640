import 'date-fns'
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: 0,
    '& .MuiTextField-root': {
      width: '30% !important',
    },
  },
}))

const Datetime = ({
  labelDate='',
  labelTime='',
  date = '2014-08-18T21:11:54',
  setDate = () => {},
  disabled=false,  
}) => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = useState(new Date())

  useEffect(() => {
    setSelectedDate(new Date(date))
  }, [date])

  const handleDateChange = date => {
    setSelectedDate(date)
    setDate(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid className={classes.root} container>
        <KeyboardDatePicker
          className={classes.datePicker}
          margin='normal'
          id='date-picker-dialog'
          label={labelDate}
          format='MM/dd/yyyy'
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disabled={disabled}

        />
        <KeyboardTimePicker
          className={classes.datePicker}
          margin='normal'
          id='time-picker'
          label={labelTime}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          disabled={disabled}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default Datetime
