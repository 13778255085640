import React from 'react'
import { Button, Container, Grid, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import Layout from '../../components/layout/layout.component'
import logo from '../../images/threader-logo.png'
import { resetPassword } from '../../firebase/firebase.utils'

import { connect } from 'react-redux'
import { selectors } from '../../state/modules'
import { createStructuredSelector } from 'reselect'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  login: {
    padding: theme.spacing(4),
  },
  image: {
    paddingBottom: theme.spacing(3),
  },
}))

const ResetPasswordPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { handleSubmit, register } = useForm()

  const onSubmit = handleSubmit(data => {
    resetPassword(data.email)
      .then(() => {
        navigate('/')
      })
      .catch(error => alert('Invalid Credential Given:' + error))
  })

  return (
    <Layout isLogin>
      <Container className={classes.container} maxWidth='xs'>
        <form onSubmit={onSubmit}>
          <Paper className={classes.login}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <img className={classes.image} src={logo} />
                  <Grid item xs={12}>
                    <h3>Recover your password</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Email'
                      name='email'
                      size='small'
                      variant='outlined'
                      inputRef={register({ required: true, maxLength: 80 })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color='primary'
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Container>
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  users: selectors.user.selectUserList,
  isLoading: selectors.user.selectUserLoading,
})

//const mapDispatchToProps = dispatch => ({
////deleteUser: id => dispatch(actions.user.deleteUser(id)),
//})

export default connect(mapStateToProps, null)(ResetPasswordPage)
