export const NAME = 'bookmark' // same as the  global state

const types = {
  BOOKMARK_TRY: `[${NAME}]/BOOKMARK_TRY`,

  SET_CURRENT_BOOKMARK: `[${NAME}]/SET_CURRENT_BOOKMARK`,
  SET_CURRENT_BOOKMARK_REQUESTED: `[${NAME}]/SET_CURRENT_BOOKMARK_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_BOOKMARK: `[${NAME}]/ADD_BOOKMARK`,
  ADD_BOOKMARK_REQUESTED: `[${NAME}]/ADD_BOOKMARK_REQUESTED`,

  UPDATE_BOOKMARK: `[${NAME}]/UPDATE_BOOKMARK`,
  UPDATE_BOOKMARK_REQUESTED: `[${NAME}]/UPDATE_BOOKMARK_REQUESTED`,

  DELETE_BOOKMARK: `[${NAME}]/DELETE_BOOKMARK`,
  DELETE_BOOKMARK_REQUESTED: `[${NAME}]/DELETE_BOOKMARK_REQUESTED`,

  BOOKMARK_FAILED: `[${NAME}]/BOOKMARK_FAILED`,
  CLEAR_CURRENT_ERROR_BOOKMARK: `[${NAME}]/CLEAR_CURRENT_ERROR_BOOKMARK`,
}

export default types
