import type from './user.types'

export const setCurrentUserRequested = user => ({
  type: type.SET_CURRENT_USER_REQUESTED,
  payload: user,
})

export const setCurrentUser = user => ({
  type: type.SET_CURRENT_USER,
  payload: user,
})

export const setCurrentUserAuth = user => ({
    type: type.SET_CURRENT_USER_AUTH,
    payload: user,
})

export const setListUser = users => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: users,
})

export const addUser = user => ({
  type: type.ADD_USER_REQUESTED,
  payload: user,
})

export const updateUser = user => ({
  type: type.UPDATE_USER_REQUESTED,
  payload: user,
})

export const updateUserProfile = user => ({
  type: type.UPDATE_USER_PROFILE_REQUESTED,
  payload: user,
})

export const deleteUser = user => ({
  type: type.DELETE_USER_REQUESTED,
  payload: user,
})

export const userFailed = error => ({
  type: type.USER_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_USER,
})
