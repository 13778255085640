import React from 'react'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
  },
}))
function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export default function BreadcrumbCustom() {
  const classes = useStyles()
  const location = useLocation()

  function handleClick(event) {
    event.preventDefault()
    console.info('You clicked a breadcrumb.')
  }
  return (
    <Breadcrumbs className={classes.root} aria-label='breadcrumb'>
      <Link color='inherit' href='/' onClick={handleClick}>
        Dashboard
      </Link>
      <Link color='inherit' href={location.pathname} onClick={handleClick}>
        {toTitleCase(location.pathname.slice(1))}
      </Link>
      <Typography color='textPrimary'>List</Typography>
    </Breadcrumbs>
  )
}
