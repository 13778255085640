import type from './thread.types'

export const addError = (errors, action) => {
  const errorId = errors.data.findIndex(
    error => error.name === action.payload.name
  )

  errors.current = action.payload

  if (errorId === -1) errors.data.push(action.payload)
  else errors.data[errorId] = action.payload

  return errors
}

export const updateThread = (list, errors, action) => {
  const updateThreadId = list.body.findIndex(
    thread => thread.id === action.payload.id
  )
  if (updateThreadId === -1)
    errors.push({
      name: type.UPDATE_THREAD,
      error: `${updateThreadId} don't exist`,
    })
  else list[updateThreadId] = action.payload
  return { list, errors }
}

export const formatData = payload => {
  const data = payload.data.map((row, key) => {
    return {
      id: key,
      rowId: row.id,
      col1: row.name,
      col2: row.status ? 'Published' : 'Draft',
      col3: row.crawl ? 'Yes' : 'No',
    }
  })

  const header = [
    { field: 'id', hide: true },
    { field: 'rowId', headerName: 'Id', width: 100 },
    { field: 'col1', headerName: 'Name', width: 150 },
    { field: 'col2', headerName: 'Status', width: 150 },
    { field: 'col3', headerName: 'Crawling', width: 150 },
  ]
  const body = [...data]

  return {
    data: payload.data,
    header,
    body,
    limit: 50,
    page: 1,
    total: 1,
  }
}
