import { all, call, put, takeLatest } from 'redux-saga/effects'
import type from './token.types'
import { API_GET, API_DELETE } from '../../../services'

const apiGet = new API_GET()
const apiDelete = new API_DELETE()

export function* tryTokenList() {
  try {
    yield put({ type: type.TOKEN_TRY })
    const response = yield apiGet.getTokens()

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    console.error(error)
    yield put({
      type: type.TOKEN_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchTokenList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryTokenList)
}

export function* tryDeleteToken(action) {
  try {
    yield put({ type: type.TOKEN_TRY })
    const id = action.payload
    const response = yield apiDelete.deleteCluster(id)

    if (!response.ok) throw new Error(response.problem)

    yield put({
      type: type.DELETE_TOKEN,
      payload: id,
    })
  } catch (error) {
    yield put({
      type: type.TOKEN_FAILED,
      payload: { name: type.DELETE_TOKEN, error: error.message },
    })
  }
}

export function* watchDeleteToken() {
  yield takeLatest(type.DELETE_TOKEN, tryDeleteToken)
}

export function* tokenSagas() {
  yield all([
    call(tryTokenList),
    watchTokenList(),
    watchDeleteToken(),
  ])
}
