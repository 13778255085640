import React, { useEffect } from 'react'
import { Grid, Hidden, withWidth, Paper, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import Menu from '../menu/menu.component'
import Navbar from './../navbar/navbar.component'
import './layout.login.css'
import BreadcrumbCustom from '../breadcrumb/breadcrumb.component'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import {user} from "../../state/modules";
import {createStructuredSelector} from "reselect/es/index";
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(25),
  },
  panelbuttons: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'flex'
  },
  buttonDelete: {
      backgroundColor: "#F40B27",
      color: "#FFFFF",
      width: 141,
      padding: 2,
      height: 44,
      "&:hover": { backgroundColor: "#bf0f28" }
  }
}))

function Layout({
  width,
  isLogin,
  showBackBtn,
  showDeleteBtn,
  hideBreadcrum,
  children,
  onSizeChange,
  onClickBack = () => {},
  onClickDelete = () => {},
  currentUserAuth
}) {
  const classes = useStyles()

  useEffect(() => {
    onSizeChange && onSizeChange(width)
  }, [width])

  const handleClickBack = () => {
    const pathname = window.location.pathname.split('/')
    onClickBack({ pathname: pathname.filter(param => param !== '') })
  }

  const handleClickDelete = () => {
      onClickDelete()
  }

  return (
    <>
      <Helmet title='Threader CMS' defer={false} />

      <Grid container className={classes.root} spacing={2}>
        {!isLogin && (
          <>
            <Grid item xs={12}>
              <Navbar rol={currentUserAuth.rol}/>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} sm={2}>
                <Menu rol={currentUserAuth.rol}/>
              </Grid>
            </Hidden>
          </>
        )}
        <Grid item xs={12} md={12} sm={12} lg={isLogin ? 12 : 10}>
          <div className={classes.paper}>
            <Paper className={classes.control}>
              {!isLogin && !hideBreadcrum && <BreadcrumbCustom />}
              <div className={classes.panelbuttons}>
                {showBackBtn && (
                  <Button
                    size='large'
                    disableElevation
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    startIcon={<ArrowBackIosIcon />}
                    onClick={handleClickBack}
                  >
                    Back to all
                  </Button>
                )}
                {showDeleteBtn && (
                    <Button
                        size='large'
                        disableElevation
                        variant='contained'
                        color='primary'
                        className={classes.buttonDelete}
                        startIcon={<RemoveCircle />}
                        onClick={handleClickDelete}
                    >
                        Delete
                    </Button>
                )}
              </div>
              {children}
            </Paper>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
    currentUserAuth: user.selectors.selectCurrentUserAuth,
})

export default connect(mapStateToProps)(withWidth()(Layout))
