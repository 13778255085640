import React, { useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import AutocompleteCustom from '../autocomplete/autocomplete.component'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import IconArrowFirst from '../../images/Icon-arrowhead-left.png'
import IconArrowLast from '../../images/Icon-arrowhead-right.png'
import IconArrowLeft from '../../images/Icon-arrow-left.png'
import IconArrowRight from '../../images/Icon-arrow-right.png'
import pluralize from 'pluralize'
import { gHeader, gBody, addEditButtons } from './table.grid.utils'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(0.4),
    paddingTop: theme.spacing(2),
    '& .MuiAutocomplete-root ': {
      marginRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  buttonDelete: {
      marginLeft: 10,
      backgroundColor: "#F40B27",
      color: "#FFFFF",
      "&:hover": { backgroundColor: "#bf0f28" }
  },
  contentPaginate: { 
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center', 
    padding: '8px 16px',
    width: 500 
  },
  contentArrow: {
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonPage: {
    marginLeft: 10,
    marginRight: 10
  },
  disabledButton: {
    filter: "opacity(0.5)"
  },
  selectInput: {
    border: "none",
    fontSize: 14,
    marginLeft: 20
  }
}))

const CustomPagination = ({ paginationProps }) => {
  const classes = useStyles()
  const { 
    page, 
    pageCount, 
    loading,
    hasNextPage, 
    hasPrevPage, 
    onNextPage, 
    onPrevPage,
    onChangeLimit,
    limit 
  } = paginationProps;

  const handleFirstPageClick = () => {
    // Go to the first page
    paginationProps.onPageChange(0);
  };

  const handleLastPageClick = () => {
    // Go to the last page
    paginationProps.onPageChange((pageCount/limit) - 1);
  };


  return (
    <div className={classes.contentPaginate}>
        <div >
          Rows per page:
          <select value={limit} className={classes.selectInput} onChange={ event => paginationProps.onChangeLimit(event.target.value)}>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
          </select>
        </div>
        <div className={classes.contentArrow}>
            <div onClick={ () => hasPrevPage && handleFirstPageClick() }>
                <img src={IconArrowFirst} width="13" height="13" className={!hasPrevPage ? classes.disabledButton : ''}/>
            </div>

            <div className={classes.buttonPage} onClick={ () => hasPrevPage && onPrevPage() }>
                <img src={IconArrowLeft} width="8" height="13" className={!hasPrevPage ? classes.disabledButton : ''}/>
            </div>
        </div>

        <div>
          <div>{(page * limit) + 1} - {(page + 1) *  limit} of {loading ? "Loading..." : pageCount}</div>
        </div>

        <div className={classes.contentArrow}>
            <div className={classes.buttonPage} onClick={ () => hasNextPage && onNextPage() }>
                <img src={IconArrowRight} width="8" height="13" className={!hasNextPage ? classes.disabledButton : ''}/>
            </div>
            <div onClick={ () => hasNextPage && handleLastPageClick() }>
                <img src={IconArrowLast} width="13" height="13" className={!hasNextPage ? classes.disabledButton : ''}/>
            </div>
        </div>
    </div>
  );
};



export default function TableGrid({
  rows = gBody,
  columns = gHeader,
  height = '50vh',
  name = 'Thread',
  paginationMode = 'server', // 	'client' | 'server'
  limit = 25,
  total = 25,
  loading,
  type,
  showRemoveBtn = false,
  options = [],
  //currentPage,
  checkboxSelection = false,
  showAddBtn = true,
  showEditBtn = true,
  showDeleteBtn = true,
  actionsWidth = 170,
  loadServerRows = () => {},
  onChangeType = () => {},
  onChangeSearch = () => {},
  onDelete = () => {},
  onEdit = () => {},
  valueOption = {},
  onClickAddNew = () => {},
  onSelectionModelChange = () => {},
  onOrderByChange = ( orderBy = "id", directionOrderBy = "asc" ) => {},
  rolUser = 3,
}) {
  // adding buttons to body
  const handleClickDetele = row => {
    onDelete(row)
  }

  const handleClickEdit = row => {
    onEdit(row)
  }
  const cols = [
    addEditButtons(
      name,
      showEditBtn,
      showDeleteBtn,
      handleClickEdit,
      handleClickDetele,
      actionsWidth
    ),
    ...columns,
  ]
  const classes = useStyles()
  const [thread, setThread] = useState('')
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState('')
  const [page, setPage] = React.useState(0);

  const fieldOrberBy = [ 
    { "rowId": "id" }, 
    { "col1": "headline" }, 
    { "col2": "authors" }, 
    { "col3": "source" }, 
    { "col4": "threads" }, 
    { "col5": "status" }, 
    { "col6": "datetime" } 
  ];

  useEffect(() => {
    setCurrentPage(`1 / ${total / limit}`)
  }, [thread, total, limit])

  const handlePageChange = params => {
    //console.log('params', params)
    setPage(params.page);
    setCurrentPage(`${params.page + 1} / ${params.pageCount}`)
    loadServerRows && loadServerRows(params)
  }

  const handlePaginationChange = (newPage) => {
    // Update the page state
    setPage(newPage);
    let params = {
      pageSize: limit,
      page: newPage,
      pageCount: total
    }
    handlePageChange(params)
  };

  const handleChangeType = (value, inputValue) => {
    setThread(value)
    onChangeType && onChangeType(value, inputValue)
  }

  const handleChangeSearch = (value, inputValue) => {
    doSearch(value || inputValue)
    onChangeSearch && onChangeSearch(value, inputValue)
  }

  const onOrderByArticles = params => {
    console.log(params)
    if (params.sortModel.length == 0)
      return false
    let columnFromTable = params.sortModel[0].field
    let directionOrderFromTable = params.sortModel[0].sort
    const field = fieldOrberBy.filter(column => column[columnFromTable])
    let orderBy = field[0][columnFromTable]
    onOrderByChange(orderBy, directionOrderFromTable)
  }

  let timeout = null

  const doSearch = data => {
    // clear all timeout
    clearTimeout(timeout)
    // Make a new timeout set to go off in 1000ms (1 second)
    timeout = setTimeout(function () {
      setSearch(Object.entries(data).length > 0 ? data.toLowerCase() : '')
    }, 1500)
  }
  const body =
    search.length > 0
      ? rows.filter(row => {
          //console.log('table row', row)
          const rowId = row.rowId ? String(row.rowId) : ''
          const col1 = row.col1 ? row.col1.toString().toLowerCase() : ''
          const col2 = row.col2 ? row.col2.toString().toLowerCase() : ''
          const col3 = row.col3 ? row.col3.toString().toLowerCase() : ''
          const col4 = row.col4 ? row.col4.toString().toLowerCase() : ''
          const col5 = row.col5 ? row.col5.toString().toLowerCase() : ''

          return (
            col1.indexOf(search) > -1 ||
            col2.indexOf(search) > -1 ||
            col3.indexOf(search) > -1 ||
            col4.indexOf(search) > -1 ||
            col5.indexOf(search) > -1 ||
            rowId.indexOf(search) > -1
          )
        })
      : rows

  const searchOptions = [
    '',
    ...rows.map(row => {
      const col1 = row.col1 ? row.col1.toString() : ''
      const col2 = row.col2 ? row.col2.toString() : ''
      const col3 = row.col3 ? row.col3.toString() : ''
      const col4 = row.col4 ? row.col4.toString() : ''
      const col5 = row.col5 ? row.col5.toString() : ''
      return col1 || col2 || col3 || col4 || col5
    }),
  ]
    

  return (
    <div style={{ height, width: '100%' }}>
      <Grid container direction='row' className={classes.root} spacing={2}>
        {type && (
          <AutocompleteCustom
            id={'autocomplete-type'}
            name={'Thread'}
            valueOption={valueOption?.name ? valueOption.name : ''}
            options={options}
            onChange={handleChangeType}
            getOptionSelected={(option, value) => option.id === value.id}
          />
        )}

        <AutocompleteCustom
          id={'autocomplete-search'}
          name={'Search'}
          onChange={handleChangeSearch}
          options={searchOptions}
          getOptionSelected={(option, value) => option === value}
        />

          {rolUser < 2 && showAddBtn &&
            <Button
                disableElevation
                variant='contained'
                color='primary'
                className={classes.button}
                size='large'
                onClick={onClickAddNew}
                startIcon={<AddCircleIcon/>}
            >
                Add {name}
            </Button>
          }

        {rolUser < 2 && showRemoveBtn &&
            <Button
              disableElevation
              variant='contained'
              color='primary'
              className={classes.buttonDelete}
              size='large'
              onClick={handleClickDetele}
              startIcon={<RemoveCircle />}
              >
              DELETE {name}
            </Button>
        }
      </Grid>

      <Typography className={classes.title} variant='h6'>
        {`${thread ? thread : pluralize(name)}`} List : {currentPage || '1 / 1'}
        {' pages'}
      </Typography>

      <DataGrid
        rows={body} // content body
        columns={cols} // content headers
        pagination
        pageSize={limit}
        rowCount={total}
        paginationMode={paginationMode}
        onPageSizeChange={handlePageChange}
        onPageChange={handlePageChange}
        loading={loading}
        checkboxSelection={rolUser < 2 ? checkboxSelection : false}
        onSelectionModelChange={(id)=>{
            onSelectionModelChange(id)
        }}
        components={{
          // Assign the custom pagination component
          Pagination: () => (
            <CustomPagination
              paginationProps={{
                page,
                pageCount: total, 
                loading,
                hasNextPage: (page + 1) *  limit < total,
                hasPrevPage: page > 0,
                onNextPage: () => handlePaginationChange(page + 1),
                onPrevPage: () => handlePaginationChange(page - 1),
                onPageChange: handlePaginationChange,
                onChangeLimit: (newLimit) =>{
                  let params = {
                    pageSize: parseInt(newLimit),
                    page: 0,
                    pageCount: total
                  }
                  handlePageChange(params)
                },
                limit
              }}
            />
          ),
        }}
        onSortModelChange={onOrderByArticles}
      />
    </div>
  )
}
