export const NAME = 'user' // same as the  global state

const types = {
  USER_TRY: `[${NAME}]/USER_TRY`,

  SET_CURRENT_USER: `[${NAME}]/SET_CURRENT_USER`,
  SET_CURRENT_USER_AUTH: `[${NAME}]/SET_CURRENT_USER_AUTH`,
  SET_CURRENT_USER_REQUESTED: `[${NAME}]/SET_CURRENT_USER_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_USER: `[${NAME}]/ADD_USER`,
  ADD_USER_REQUESTED: `[${NAME}]/ADD_USER_REQUESTED`,

  UPDATE_USER: `[${NAME}]/UPDATE_USER`,
  UPDATE_USER_REQUESTED: `[${NAME}]/UPDATE_USER_REQUESTED`,
  UPDATE_USER_PROFILE_REQUESTED: `[${NAME}]/UPDATE_USER_PROFILE_REQUESTED`,

  DELETE_USER: `[${NAME}]/DELETE_USER`,
  DELETE_USER_REQUESTED: `[${NAME}]/DELETE_USER_REQUESTED`,

  GOOGLE_SIGN_IN_START: `[${NAME}]/GOOGLE_SIGN_IN_START`,
  GOOGLE_SIGN_IN_START_REQUESTED: `[${NAME}]/GOOGLE_SIGN_IN_START_REQUESTED`,

  EMAIL_SIGN_IN_START: `[${NAME}]/EMAIL_SIGN_IN_START`,
  EMAIL_SIGN_IN_START_REQUESTED: `[${NAME}]/EMAIL_SIGN_IN_START_REQUESTED`,

  SIGN_IN_SUCCESS: `[${NAME}]/SIGN_IN_SUCCESS`,
  SIGN_IN_SUCCESS_REQUESTED: `[${NAME}]/SIGN_IN_SUCCESS_REQUESTED`,

  USER_FAILED: `[${NAME}]/USER_FAILED`,
  CLEAR_CURRENT_ERROR_USER: `[${NAME}]/CLEAR_CURRENT_ERROR_USER`,
}

export default types
