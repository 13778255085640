import { createSelector } from 'reselect'
import { NAME } from './bookmark.types'

export const selectBookmark = state => state[NAME]

export const selectBookmarkLoading = createSelector(
  [selectBookmark],
  bookmark => bookmark.isLoading
)

export const selectCurrentBookmark = createSelector(
  [selectBookmark],
  bookmark => bookmark.currentBookmark
)

export const selectBookmarkList = createSelector(
  [selectBookmark],
  bookmark => bookmark.list
)
