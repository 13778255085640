import { createSelector } from 'reselect'
import { NAME } from './article.types'

export const selectArticle = state => state[NAME]

export const selectArticleLoading = createSelector(
  [selectArticle],
  article => article.isLoading
)

export const selectCurrentArticle = createSelector(
  [selectArticle],
  article => article.currentArticle
)

export const selectArticleList = createSelector(
  [selectArticle],
  article => article.list
)

export const selectArticleDelete = createSelector(
    [selectArticle],
    article => article.isDelete
)

export const selectArticleErrors = createSelector(
  [selectArticle],
  article => article.errors
)

export const selectArticleCurrentError = createSelector(
  [selectArticleErrors],
  error => error.current
)
