import { user } from './user'
import { thread } from './thread'
import { article } from './article'
import { bookmark } from './bookmark'
import { location } from './location'
import { timeline } from './timeline'
import { weather } from './weather'
import { source } from './source'
import { cluster } from './cluster'
import { token } from './token'

/**
 * Root states.
 */
export const States = {
  user: user.initialState,
  thread: thread.initialState,
  article: article.initialState,
  bookmark: bookmark.initialState,
  location: location.initialState,
  timeline: timeline.initialState,
  weather: weather.initialState,
  source: source.initialState,
  cluster: cluster.initialState,
  token: token.initialState
}

/**
 * Root reducers.
 */
export const reducers = {
  user: user.reducer,
  thread: thread.reducer,
  article: article.reducer,
  bookmark: bookmark.reducer,
  location: location.reducer,
  timeline: timeline.reducer,
  weather: weather.reducer,
  source: source.reducer,
  cluster: cluster.reducer,
  token: token.reducer
}

/**
 * Root types
 */

export const types = {
  user: user.types,
  thread: thread.types,
  article: article.types,
  bookmark: bookmark.types,
  location: location.types,
  timeline: timeline.types,
  weather: weather.types,
  source: source.types,
  cluster: cluster.types,
  token: token.types
}

/**
 * Root sagas
 */

export const sagas = {
  user: user.sagas,
  thread: thread.sagas,
  article: article.sagas,
  bookmark: bookmark.sagas,
  location: location.sagas,
  timeline: timeline.sagas,
  weather: weather.sagas,
  source: source.sagas,
  cluster: cluster.sagas,
  token: token.sagas
}

/**
 * Root utils.
 */
export const utils = {
  user: user.utils,
  thread: thread.utils,
  article: article.utils,
  bookmark: bookmark.utils,
  location: location.utils,
  timeline: timeline.utils,
  weather: weather.utils,
  cluster: cluster.utils,
  token: token.utils
}

/**
 * Root selectors
 */
export const selectors = {
  user: user.selectors,
  thread: thread.selectors,
  article: article.selectors,
  bookmark: bookmark.selectors,
  location: location.selectors,
  timeline: timeline.selectors,
  weather: weather.selectors,
  source: source.selectors,
  cluster: cluster.selectors,
  token: token.selectors
}

/**
 * Root actions.
 */
export const actions = {
  user: user.actions,
  thread: thread.actions,
  article: article.actions,
  bookmark: bookmark.actions,
  location: location.actions,
  timeline: timeline.actions,
  weather: weather.actions,
  source: source.actions,
  cluster: cluster.actions,
  token: token.actions
}

// can you access to especific action
export { user, thread, article, bookmark, location, timeline, weather, source, cluster, token }
