import { titleCase } from '../../../components/tags/tags.utils'
import type from './location.types'

export const addError = (errors, action) => {
  const errorId = errors.data.findIndex(
    error => error.name === action.payload.name
  )

  errors.current = action.payload

  if (errorId === -1) errors.data.push(action.payload)
  else errors.data[errorId] = action.payload

  return errors
}

export const updateLocation = (list, errors, action) => {
  const updateLocationId = list.findIndex(
    location => location.id === action.payload.id
  )
  if (updateLocationId === -1)
    errors.push({
      name: type.UPDATE_LOCATION,
      error: `${updateLocationId} don't exist`,
    })
  else list[updateLocationId] = action.payload
  return { list, errors }
}

export const formatData = payload => {
  const body = payload.data.map(row => {
    return {
      id: row.id,
      rowId: row.id,
      col1: titleCase(row.city),
      col2: titleCase(row.country),
    }
  })

  const data = payload.data.map(element => ({
    ...element,
    name: element.display,
  }))

  const header = [
    { field: 'id', hide: true },
    { field: 'rowId', headerName: 'Id', width: 100 },
    { field: 'col1', headerName: 'City', width: 150 },
    { field: 'col2', headerName: 'Country', width: 250 },
  ]

  return {
    data,
    header,
    body,
    limit: 50,
    page: 1,
    total: payload.data.length,
  }
}
