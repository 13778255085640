import reducer, { initialState } from './timeline.reducer'
import types from './timeline.types'

import * as sagas from './timeline.sagas'
import * as actions from './timeline.actions'
import * as utils from './timeline.utils'
import * as selectors from './timeline.selectors'

export const timeline = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
