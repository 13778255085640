import { all, call, put, takeLatest } from 'redux-saga/effects'
import type from './source.types'
import { API_GET, API_POST, API_PUT, API_DELETE } from '../../../services'

const apiGet = new API_GET()
const apiPost = new API_POST()
const apiPut = new API_PUT()
const apiDelete = new API_DELETE()

export function* trySourceList() {
  try {
    yield put({ type: type.SOURCE_TRY })
    const response = yield apiGet.getSources()

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.SOURCE_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchSourceList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, trySourceList)
}

function* tryCurrentSource(action) {
  try {
    yield put({ type: type.SOURCE_TRY })
    const id = action.payload
    const response = yield apiGet.getSources(id)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.SET_CURRENT_SOURCE, payload })
  } catch (error) {
    yield put({
      type: type.SOURCE_FAILED,
      payload: { name: type.SET_CURRENT_SOURCE, error: error.message },
    })
  }
}

export function* watchCurrentSource() {
  yield takeLatest(type.SET_CURRENT_SOURCE_REQUESTED, tryCurrentSource)
}

export function* tryAddSource(action) {
  try {
    yield put({ type: type.SOURCE_TRY })
    const response = yield apiPost.addSource(action.payload)

    if (!response.ok) throw new Error(response.problem)
    const payload = yield response.data

    yield put({
      type: type.ADD_SOURCE,
      payload,
    })
  } catch (error) {
    yield put({
      type: type.SOURCE_FAILED,
      payload: { name: type.ADD_SOURCE, error: error.message },
    })
  }
}

export function* watchAddSource() {
  yield takeLatest(type.ADD_SOURCE_REQUESTED, tryAddSource)
}

export function* tryDeleteSource(action) {
  try {
    yield put({ type: type.SOURCE_TRY })
    const id = action.payload
    const response = yield apiDelete.deleteSource(id)

    if (!response.ok) throw new Error(response.problem)

    yield put({
      type: type.DELETE_SOURCE,
      payload: id,
    })
  } catch (error) {
    yield put({
      type: type.SOURCE_FAILED,
      payload: { name: type.DELETE_SOURCE, error: error.message },
    })
  }
}

export function* watchDeleteSource() {
  yield takeLatest(type.DELETE_SOURCE_REQUESTED, tryDeleteSource)
}

export function* tryUpdateSource(action) {
  try {
    yield put({ type: type.SOURCE_TRY })
    const { id, data } = action.payload
    const response = yield apiPut.updateSource(id, data)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data
    yield put({
      type: type.UPDATE_SOURCE,
      payload: { id, ...payload },
    })
  } catch (error) {
    yield put({
      type: type.SOURCE_FAILED,
      payload: { name: type.UPDATE_SOURCE, error: error.message },
    })
  }
}

export function* watchUpdateSource() {
  yield takeLatest(type.UPDATE_SOURCE_REQUESTED, tryUpdateSource)
}

export function* sourceSagas() {
  yield all([
    call(trySourceList),
    watchSourceList(),
    watchAddSource(),
    watchCurrentSource(),
    watchDeleteSource(),
    watchUpdateSource(),
  ])
}
