import React from 'react'
import { Button } from '@material-ui/core'

export const gHeader = [
  { field: 'id', hide: true },
  { field: 'rowId', headerName: 'Id', width: 250 },
  { field: 'col1', headerName: 'Name', width: 150 },
  { field: 'col2', headerName: 'Column 2', width: 150 },
  { field: 'col3', headerName: 'Column 3', width: 150 },
]

export const gBody = []

export const addEditButtons = (
  name = '',
  showEditBtn = true,
  showDeleteBtn = true,
  handleClickEdit = () => {},
  handleClickDetele = () => {},
  width = 170
) => ({
  field: name,
  headerName: 'Actions',
  sortable: false,
  width: width,
  disableClickEventBubbling: true,
  renderCell: params => {
    const { row } = params

    const onClick = () => {
      handleClickEdit(row)
    }
    const onDelete = () => {
      handleClickDetele(row)
    }

    return (
      <div>
        {showEditBtn && <Button onClick={onClick}>Edit</Button>}
        {showDeleteBtn && <Button onClick={onDelete}>Delete</Button>}
      </div>
    )
  },
})
