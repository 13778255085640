import type from './cluster.types'

export const setCurrentCluster = cluster => ({
  type: type.SET_CURRENT_CLUSTER,
  payload: cluster,
})

export const setCurrentClusterRequested = cluster => ({
  type: type.SET_CURRENT_CLUSTER_REQUESTED,
  payload: cluster,
})

export const setListCluster = clusters => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: clusters,
})

export const addCluster = payload => ({
  type: type.ADD_CLUSTER_REQUESTED,
  payload: payload,
})

export const updateCluster = cluster => ({
  type: type.UPDATE_CLUSTER_REQUESTED,
  payload: cluster,
})

export const deleteCluster = id => ({
  type: type.DELETE_CLUSTER_REQUESTED,
  payload: id,
})

export const deleteArticleCluster = payload => ({
  type: type.DELETE_ARTICLE_CLUSTER_REQUESTED,
  payload: payload,
})

export const clusterFailed = error => ({
  type: type.CLUSTER_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_CLUSTER,
})
