import React, {useState, useEffect} from 'react'
import {
    Grid,
    Button,
    TextField,
    ListItemText,
    ListItem,
    List,
    IconButton,
    ListItemSecondaryAction
} from '@material-ui/core'

import Delete from '@material-ui/icons/Delete'

export default function AddListItem ({
    listItem,
    onChange
}) {

    const [term, setTerm] = useState("")
    const [list, setList] = useState(()=>{
        let value = []
        try {
            value = JSON.parse(listItem)
        }catch(e){
            console.error(e)
        }
        return value
    })

    useEffect(() => {
        onChange(list)
    }, [list])

    const handleDelete = (item) => {
        setList(() => new Set([...list].filter(v => v.keyword != item.keyword)))
    }

    const handleAdd = () => {
        let newTerm = term.trim().toLowerCase();
        let resultFind = [...list].findIndex(v => v.keyword.toLowerCase() == newTerm);
        if (resultFind == -1)
            setList(prev => new Set(prev).add({ keyword: term.trim(), synonimous: '', definition: '', source: '' }))

        setTerm('')
    }


    return (
        <Grid container
              direction="row">
            <Grid container>
                <Grid item xs={4}>
                    <TextField
                        id='thread-name'
                        label='Keywords'
                        placeholder='Keyword'
                        value={term}
                        onInput={e => setTerm(e.target.value)}
                    />
                </Grid>
                <Button
                    disabled={ term.length > 1 ? false : true }
                    size='large'
                    variant='contained'
                    disableElevation
                    color='primary'
                    style={{ marginLeft: 20}}
                    onClick={handleAdd}
                >
                    Add
                </Button>

            </Grid>
            <Grid item xs={5}>
                <List>
                { [...list].map((item, key) =>
                    <ListItem
                        button
                        dense
                        key={key}>
                        <ListItemText
                            primary={item.keyword}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={()=>handleDelete(item)}>
                                <Delete fontSize="small" />
                            </IconButton>
                        </ListItemSecondaryAction>

                    </ListItem>)
                }
                </List>
            </Grid>
        </Grid>
    )
}
