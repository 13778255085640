import { all, put, takeLatest } from 'redux-saga/effects'
import type from './bookmark.types'
import Service from '../../../services'

const Ser = Service()
const apiGet = new Ser.API_GET()
const URL = process.env.URL_API_JSON_API

export function* tryBookmarksList() {
  try {
    //const response = yield call(service.apisauce.get, '/bookmarks')
    const response = yield apiGet.getBookmarks()
    //const payload = yield call([response, 'json'])
    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.BOOKMARK_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchBookmarkList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryBookmarksList)
}

const apiGetParser = new Ser.API_GET(process.env.URL_API_PARSER)
//console.log('parserAPI', parserAPI)

function* tryCurrentBookmark(action) {
  console.log('++++++++++ setCurrentBookmark bookmark', action)
  yield put({ type: type.BOOKMARK_TRY })
  try {
    //const response = yield call(parserAPI.apisauce.get, '/bookmarks')
    //
    const response = yield apiGetParser.getBookmark(
      'https://www.washingtonpost.com/health/2020/11/23/coronavirus-cases-deaths-hospitalizatons-lag/',
      'covid-19'
    )

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.SET_CURRENT_BOOKMARK, payload })
  } catch (error) {
    yield put({
      type: type.BOOKMARK_FAILED,
      payload: { name: type.SET_CURRENT_BOOKMARK, error: error.message },
    })
  }
}

export function* watchCurrentBookmark() {
  yield takeLatest(type.SET_CURRENT_BOOKMARK_REQUESTED, tryCurrentBookmark)
}

export function* addBookmark(action) {
  try {
    const response = yield fetch(`${URL}/bookmarks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    })

    const newBookmark = yield response.json()

    yield put({
      type: type.ADD_BOOKMARK,
      payload: newBookmark.data,
    })
  } catch (error) {
    yield put({
      type: type.BOOKMARK_FAILED,
      payload: { name: type.ADD_BOOKMARK, error: error.message },
    })
  }
}

export function* watchAddBookmark() {
  yield takeLatest(type.ADD_BOOKMARK_REQUESTED, addBookmark)
}

export function* deleteBookmark(action) {
  yield put({ type: type.BOOKMARK_TRY })
  try {
    console.log('++++++++++ delete bookmark', action)
    const response = yield fetch(`${URL}/bookmarks/` + action.payload, {
      method: 'DELETE',
    })

    const deletedBookmark = yield response.json()

    yield put({
      type: type.DELETE_BOOKMARK,
      //payload: action.payload,
      payload: deletedBookmark,
    })
  } catch (error) {
    yield put({
      type: type.BOOKMARK_FAILED,
      payload: { name: type.DELETE_BOOKMARK, error: error.message },
    })
  }
}

export function* watchDeleteBookmark() {
  yield takeLatest(type.DELETE_BOOKMARK_REQUESTED, deleteBookmark)
}

export function* updateBookmark(action) {
  try {
    const response = yield fetch(`${URL}/bookmarks/` + action.payload.id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    })

    const updatedBookmark = yield response.json()

    yield put({
      type: type.UPDATE_BOOKMARK,
      payload: updatedBookmark.data,
    })
  } catch (error) {
    yield put({
      type: type.BOOKMARK_FAILED,
      payload: { name: type.UPDATE_BOOKMARK, error: error.message },
    })
  }
}

export function* watchUpdateBookmark() {
  yield takeLatest(type.UPDATE_BOOKMARK_REQUESTED, updateBookmark)
}

export function* bookmarkSagas() {
  yield all([
    //call(tryBookmarksList),
    //call(tryCurrentBookmark),
    watchBookmarkList(),
    watchCurrentBookmark(),
    watchAddBookmark(),
    watchDeleteBookmark(),
    watchUpdateBookmark(),
  ])
}
