import reducer, { initialState } from './thread.reducer';
import types from './thread.types';

import * as sagas from './thread.sagas';
import * as actions from './thread.actions';
import * as utils from './thread.utils';
import * as selectors from './thread.selectors';

export const thread = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
};
