import { createSelector } from 'reselect'
import { NAME } from './cluster.types'

export const selectCluster = state => state[NAME]

export const selectClusterLoading = createSelector(
  [selectCluster],
  cluster => cluster.isLoading
)

export const selectCurrentCluster = createSelector(
  [selectCluster],
  cluster => cluster.currentCluster
)

export const selectClusterList = createSelector(
  [selectCluster],
  cluster => cluster.list
)

export const selectClusterErrors = createSelector(
  [selectCluster],
  cluster => cluster.errors
)

export const selectClusterCurrentError = createSelector(
  [selectClusterErrors],
  error => error.current
)
