import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from '../src/state/store'
import TopLayout from '../src/components/toplayout/topLayout.component'

import './index.css'

import App from './App'
import Loading from './components/loading/loading.component'

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

console.info('Version: ', process.env.REACT_APP_VERSION)

ReactDOM.render(
  <Provider store={store}>
    <TopLayout>
      <BrowserRouter>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <App />
        </PersistGate>
      </BrowserRouter>
    </TopLayout>
  </Provider>,
  document.getElementById('root')
)
