import {
    Grid,
    Button,
    makeStyles,
    TextField
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import {selectors, actions, user} from '../../state/modules'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'
import {validURL} from "../article/articles.utils";
import TableGrid from '../../components/table.grid/table.grid.component'
import * as utils from '../../state/modules/article/article.utils'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
        '& > *': {
            margin: theme.spacing(1),
        },
        '& button': {
            margin: theme.spacing(1),
        },
    },
    cover: {
        width: '100%',
        height: 400,
        marginLeft: '.5rem',
        marginTop: '2rem',
    },
    source: {
        width: 60,
        height: 60,
    },
}))

function ClusterPageDetail({
    currentArticle,
    currentCluster,
    addCluster,
    updateCluster,
    setCurrentCluster,
    currentArticleError,
    clearCurrenArticleError,
    currentUserAuth,
    addArticleCrawler,
    setCurrentArticle,
    clusterLoading,
    addArticleCluster,
    deleteArticleCluster
}) {
    const classes = useStyles()
    const [size, setSize] = useState()
    const pageName = 'cluster'
    const [modal, setModal] = useState({ ...defaultDataModal })
    const [cluster, setCluster] = useState({})
    const [link, setLink] = useState('')

    const navigate = useNavigate()
    const rolUser = currentUserAuth?.rol > -1 ? currentUserAuth.rol : 3

    useEffect(() => {
        if (rolUser != 0) {
            return navigate(`/`)
        }
    },[])

    const { id } = useParams()

    useEffect(() => {
        if (id !== 'null') {
            let listarticles = { data: currentCluster.articles }
            let formatListArticles = utils.formatData(listarticles)
            setCluster(formatListArticles)
        }
    }, [id])

    useEffect(() => {
        if (currentArticle && cluster?.body) {
            let newArticle = {
                id: currentArticle.id,
                rowId: currentArticle.id,
                col1: currentArticle.content?.headline,
                col2: currentArticle.authors,
                col3: currentArticle.source?.name,
                col4: currentArticle.content?.thread.join(', '),
                col5: currentArticle.status,
                col6: currentArticle.date
            }
            setCluster({...cluster, body: [...cluster.body, newArticle], data: [...cluster.data, currentArticle]})
            addArticleCluster({cluster_id: currentCluster.id, article_id: currentArticle.id})
        }
    }, [currentArticle?.id])

    useEffect(() => {
        if (currentArticleError.name) {
            setModal({
                open: true,
                title: 'Error!',
                description: `${currentArticleError.error}`,
                type: 'error',
                id: null,
            })
        }
    }, [currentArticleError.name])


    const handleSizeChange = size => {
        setSize(size)
    }

    const handleDeleteSubmit = (payload) => {
        setModal({
            open: true,
            title: 'Notice!',
            description: 'this article # '+payload.id+' will be delete',
            type: 'delete',
            data: {cluster_id: currentCluster.id, article_id: payload.id},
        })
    }

    const handleClickBack = ({ pathname }) => {
        setCurrentCluster(null)
        navigate(`/${pathname[0]}`)
    }

    const handleSubmit = event => {
        event.preventDefault()

        const type = 'add';
        //const type = id === 'null' ? 'add' : 'update'
        setModal({
            open: true,
            title: 'Notice!',
            description: `Cluster will be ${type}`,
            type,
            id,
        })

    }

    const handleCrawlFromLinks = (link) => {
        if (!validURL(link)) {
            alert('Please check link from article, this link is not validate')
            return
        }

        let linkData = {
            link: btoa(encodeURI(link)),
            main_tag: ""
        }


        setModal({
            open: true,
            title: 'Notice!',
            description: `"${link}" will be crawl`,
            type: 'crawl',
            data: linkData,
            id,
        })
    }

    const handleCloseModal = () => {
        setModal({ ...defaultDataModal, open: false })
    }

    const handleAccept = id => {
        if (modal.type === 'delete') {
            deleteArticleCluster(modal.data)
        } else if (modal.type === 'add') {
            addCluster(cluster)
        } else if (modal.type === 'update') {
            updateCluster({ id, data: cluster })
        } else if (modal.type === 'crawl') {
            setCurrentArticle(null)
            return addArticleCrawler(modal.data)
        } else if (modal.type === 'message') {
            return clearCurrenArticleError()
        }
        else if (modal.type === 'error') {
            setModal({...defaultDataModal, open: false})
            return clearCurrenArticleError()
        }
        navigate(`/${pageName.toLowerCase()}`)
    }

    const onDeleteHandler = (article) => {
        handleDeleteSubmit(article)
    }

    return (
        <Layout
            hideBreadcrum
            showBackBtn
            onClickBack={handleClickBack}
            onSizeChange={handleSizeChange}
        >
            <form
                className={classes.root}
                style={{ width: size === 'xl' ? '60%' : '100%' }}
                //noValidate
                autoComplete='off'
                onSubmit={handleSubmit}
            >
                 {/* <ListArticles
                    listItem={cluster.articles}
                    onChange={(items) => setCluster({ ...cluster, articles: [...items] })}
                    addLink={link => {
                        handleCrawlFromLinks(link)
                    }}
                /> */}

                <Grid container
                    direction="row">
                    <Grid container>
                        <Grid item xs={10}>
                            <TextField
                                id='article-link'
                                label='link from article'
                                placeholder='paste your link here'
                                value={link}
                                onInput={e => setLink(e.target.value)}
                            />
                        </Grid>
                        <Button
                            disabled={ link.length > 1 ? false : true }
                            size='large'
                            variant='contained'
                            disableElevation
                            color='primary'
                            style={{ marginLeft: 20}}
                            onClick={ () => handleCrawlFromLinks(link)}>
                            Add Article
                        </Button>

                    </Grid>
                </Grid>

                <TableGrid
                    name={"ARTICLES"}
                    rows={cluster.body}
                    columns={cluster.header}
                    loading={clusterLoading}
                    limit={25}
                    showAddBtn={false}
                    showEditBtn={false}
                    onDelete={(row)=>onDeleteHandler(row)}
                    rolUser={rolUser}
                />


                <ServerModal
                    isOpen={modal.open}
                    title={modal.title}
                    description={modal.description}
                    id={modal.id}
                    showBtns
                    onAccept={handleAccept}
                    onClose={handleCloseModal}
                />
            </form>
        </Layout>
    )
}

const mapStateToProps = createStructuredSelector({
    currentArticle: selectors.article.selectCurrentArticle,
    currentCluster: selectors.cluster.selectCurrentCluster,
    clusterLoading: selectors.cluster.selectClusterLoading,
    currentUserAuth: user.selectors.selectCurrentUserAuth,
    currentArticleError: selectors.article.selectArticleCurrentError,
})

const mapDispatchToProps = dispatch => ({
    setCurrentArticle: article => dispatch(actions.article.setCurrentArticle(article)),
    clearCurrenArticleError: () => dispatch(actions.article.clearCurrentError()),
    addArticleCrawler: linkData => dispatch(actions.article.addArticleCrawler(linkData)),
    setCurrentCluster: cluster => dispatch(actions.cluster.setCurrentCluster(cluster)),
    addArticleCluster: payload => dispatch(actions.cluster.addCluster(payload)),
    deleteArticleCluster: payload => dispatch(actions.cluster.deleteArticleCluster(payload)),
    deleteCluster: id => dispatch(actions.cluster.deleteCluster(id)),
    updateCluster: cluster => dispatch(actions.cluster.updateCluster(cluster)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClusterPageDetail)
