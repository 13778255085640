import firebase from './firebase.config'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'


export const authUser = userData => {
  let { email, password } = userData

  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(userCredential => resolve(userCredential.user))
      .catch(error => reject(error))
  })
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser
    if (user) resolve(auth.currentUser)
    reject
  })
}

export const updateProfileUser = payload => {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser
    if (user) resolve(user.updateProfile(payload))
    reject
  })
}

export const resetPassword = email => {
  return auth.sendPasswordResetEmail(email)
}

export const updateUserDocument = async user => {
  if (!user) return

  const userRef = firestore.doc(`users/${user.id}`)

  const snapShot = await userRef.get()

  if (snapShot.exists) {
    try {
      await userRef.update(user)
      return {...user, local_id: user.userId };
    } catch (error) {
      console.log('error update user', error.message)
    }
  }

  return userRef
}

export const deleteUserDocument = async userId => {
    if (!userId) return

    try {
         await firestore.collection("users").where("userId", "==", userId).get()
            .then(querySnapshot => {
                querySnapshot.docs[0].ref.delete();
            });

        return true

    } catch (error) {
            console.log('error delete user', error.message)
            return false
    }
}

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)

  const snapShot = await userRef.get()

  if (!snapShot.exists) {
    const { displayName, email } = userAuth
    const createdAt = new Date()
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      })
    } catch (error) {
      console.log('error creating user', error.message)
    }
  }

  return userRef
}

export const getUserDocument = async (user) => {
    try {
        const userRef = await firestore.collection("users").where("userId", "==", user.uid);

        const snapShot = await userRef.get()

        let data = {};

        await snapShot.forEach(doc => {
            data = doc.data()
        });

        return {...user, ...data}

    } catch (error) {
        console.log('error user', error.message)
        return user
    }

}

export const getAllUsers = async () => {
  const collectionRef = firestore.collection('users')

  const collectionSnapshot = await collectionRef.get()

  const response = convertCollectionsSnapshotToMap(collectionSnapshot)

  return response
  //if (!collectionSnapshot.exists) {
  //try {
  //console.log('collection users not exists')
  //} catch (error) {
  //console.log('error creating user', error.message)
  //}
  //}
}

export const addUserDocument = async (userForm) => {
    try {

        let user = {};

        if (userForm.userId == null){
          const fireAuth = firebase.auth();
          const userCredential = await fireAuth.createUserWithEmailAndPassword(userForm.email, '123456');
          user = {  ...userForm, userId: userCredential.user.uid };
          const collectionRef = firestore.collection('users')
          const newUser = await collectionRef.doc().set({...user})
          return newUser
        }else{
            if (!userForm.id){
                const collectionRef = firestore.collection('users')
                const newUser = await collectionRef.doc().set({...userForm})
                return newUser
            }

            updateUserDocument(userForm)
        }

    }catch(error) {
        console.error('error write', error)
    }

}

export const addUserExistsFromAuth = async user => {
    try {
        const collectionRef = firestore.collection('users')
        const newUser = await collectionRef.doc().set({
            ...user
        }).catch(function (error) {
            console.error('error write', error)
        })
        if (newUser) {
            //sendEmailVerification(userCredential.user.email)
            return newUser
        }
    }catch(error) {
        console.error('error write', error)
    }
}

export const convertCollectionsSnapshotToMap = collections => {
  const transformedCollection = collections.docs.map(doc => {
    //console.log('doc.data', doc.data())
    //const { items } = doc.data()

    return {
      //routeName: encodeURI(title.toLowerCase()),
      id: doc.id,
      //title,
      item: doc.data(),
    }
  })

  return transformedCollection
  //return transformedCollection.reduce((accumulator, collection) => {
  ////accumulator[collection.title.toLowerCase()] = collection
  //return accumulator
  //}, {})
}

export const logOut = () =>{
    auth.signOut();
}

export const auth = firebase.auth()
export const firestore = firebase.firestore()

export const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.setCustomParameters({ prompt: 'select_account' })
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider)

export default firebase

//export const addCollectionAndDocuments = async (
//collectionKey,
//objectsToAdd
//) => {
//const collectionRef = firestore.collection(collectionKey)

//const batch = firestore.batch()
//objectsToAdd.forEach(obj => {
//const newDocRef = collectionRef.doc()
//batch.set(newDocRef, obj)
//})

//return await batch.commit()
//}
