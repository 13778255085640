import { createSelector } from 'reselect'
import { NAME } from './token.types'

export const selectToken = state => state[NAME]

export const selectTokenLoading = createSelector(
  [selectToken],
  token => token.isLoading
)

export const selectCurrentToken = createSelector(
  [selectToken],
  token => token.currentToken
)

export const selectTokenList = createSelector(
  [selectToken],
  token => token.list
)

export const selectTokenErrors = createSelector(
  [selectToken],
  token => token.errors
)

export const selectTokenCurrentError = createSelector(
  [selectTokenErrors],
  error => error.current
)
