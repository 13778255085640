import type from './thread.types'

export const setCurrentThread = thread => ({
  type: type.SET_CURRENT_THREAD,
  payload: thread,
})

export const setCurrentThreadRequested = thread => ({
  type: type.SET_CURRENT_THREAD_REQUESTED,
  payload: thread,
})

export const setListThread = threads => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: threads,
})

export const addThread = thread => ({
  type: type.ADD_THREAD_REQUESTED,
  payload: thread,
})

export const updateThread = thread => ({
  type: type.UPDATE_THREAD_REQUESTED,
  payload: thread,
})

export const deleteThread = thread_id => ({
  type: type.DELETE_THREAD_REQUESTED,
  payload: thread_id,
})

export const threadFailed = error => ({
  type: type.THREAD_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_THREAD,
})
