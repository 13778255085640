import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  Button,
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import Layout from '../../components/layout/layout.component'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../state/modules'
import { useNavigate } from 'react-router-dom'
import { getCurrentUser } from '../../firebase/firebase.utils'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& button': {
      margin: theme.spacing(1),
    },
  },
  cover: {
    width: '100%',
    height: 400,
    marginLeft: '.5rem',
    marginTop: '2rem',
  },
}))

function UserProfielDetail({ currentUser, updateUserProfile }) {
  const classes = useStyles()
  const [state, setState] = useState({
    name: '',
    email: { val: '', verified: false },
    id: null,
    size: 0,
  })
  const navigate = useNavigate()

  const handleSizeChange = size => {
    setState({ ...state, size })
  }

  useEffect(() => {
    getCurrentUser().then(user => {
      if (user) {
        const { displayName, email, emailVerified, id } = user
        console.log('user', user)
        setState({
          name: displayName || '',
          email: { val: email, verified: emailVerified },
          id,
        })
      }
    })
  }, [])

  const handleClose = () => {
    navigate('/dashboard')
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const result = await updateUserProfile({
      displayName: state.name,
    })
    if (result) {
      alert('success')
      navigate('/dashboard')
    }
  }

  return (
    <Layout onSizeChange={handleSizeChange}>
      <form
        className={classes.root}
        style={{ width: state.size === 'xl' ? '60%' : '100%' }}
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <TextField
          required
          id='standard-basic'
          label='Name'
          defaultValue={currentUser ? currentUser.item.name : '(Name)'}
          value={state.name}
          onInput={e => setState({ ...state, name: e.target.value })}
        />
        <TextField
          required
          id='standard-basic'
          label='email'
          defaultValue={currentUser ? currentUser.item.email : '(Email)'}
          value={state.email.val}
          onInput={e =>
            setState({
              ...state,
              email: { ...state.email, val: e.target.value },
            })
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={state.email.verified}
              onChange={() =>
                setState({
                  ...state,
                  email: { ...state.email, verified: !state.email.verified },
                })
              }
              inputProps={{ 'aria-label': 'primary checkbox' }}
              color='primary'
              name='checkedF'
            />
          }
          label='Email Verified'
        />

        <Box m={5} />
        <Grid container justify='flex-end'>
          <Button size='large' variant='outlined' onClick={handleClose}>
            Close
          </Button>
          <Button
            size='large'
            variant='contained'
            disableElevation
            color='primary'
            type='submit'
          >
            Update
          </Button>
        </Grid>
      </form>
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  users: selectors.user.selectUserList,
  currentUser: selectors.user.selectCurrentUser,
  loadingUsers: selectors.user.selectUserLoading,
})

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(actions.user.setCurrentUserEdit(user)),
  updateUserProfile: user => dispatch(actions.user.updateUserProfile(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfielDetail)
