import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const gOptions = ['', 'Option 2']

export default function AutocompleteCustom({
  id,
  name,
  options = gOptions,
  onChange,
  fullWidth = false,
  valueOption = {},
}) {
  const [value, setValue] = React.useState('')
  const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    onChange && onChange(value, inputValue)
  }, [value, inputValue])

  useEffect(() => {
    if (Object.keys(valueOption).length > 0) setValue(valueOption)
  }, [valueOption])

  return (
    <Autocomplete
      value={value}
      getOptionSelected={(option, value) => {
        return option === value
      }}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      id={id}
      options={[...new Set(options)]}
      style={{ width: fullWidth ? '100%' : 300 }}
      renderInput={params => (
        <TextField {...params} label={name} variant='outlined' />
      )}
    />
  )
}
