export const NAME = 'thread' // same as the  global state

const types = {
  THREAD_TRY: `[${NAME}]/THREAD_TRY`,

  SET_CURRENT_THREAD: `[${NAME}]/SET_CURRENT_THREAD`,
  SET_CURRENT_THREAD_REQUESTED: `[${NAME}]/SET_CURRENT_THREAD_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_THREAD: `[${NAME}]/ADD_THREAD`,
  ADD_THREAD_REQUESTED: `[${NAME}]/ADD_THREAD_REQUESTED`,

  UPDATE_THREAD: `[${NAME}]/UPDATE_THREAD`,
  UPDATE_THREAD_REQUESTED: `[${NAME}]/UPDATE_THREAD_REQUESTED`,

  DELETE_THREAD: `[${NAME}]/DELETE_THREAD`,
  DELETE_THREAD_REQUESTED: `[${NAME}]/DELETE_THREAD_REQUESTED`,

  THREAD_FAILED: `[${NAME}]/THREAD_FAILED`,
  CLEAR_CURRENT_ERROR_THREAD: `[${NAME}]/CLEAR_CURRENT_ERROR_THREAD`,
}

export default types
