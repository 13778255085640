import type from './thread.types'
import * as utils from './thread.utils'

export const initialState = {
  currentThread: null,
  list: {
    data: [],
    body: [],
    limit: 50,
    page: 1,
    total: 1,
  },
  errors: {
    current: {},
    data: [],
  },
  isLoading: null,
}

const threadReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.THREAD_TRY:
      return {
        ...state,
        isLoading: true,
      }

    case type.SET_CURRENT_THREAD:
      return {
        ...state,
        currentThread: action.payload,
        isLoading: false,
      }

    case type.LIST_SUCCESS: {
      const list = utils.formatData(action.payload)
      return { ...state, isLoading: false, list }
    }

    case type.ADD_THREAD: {
      const data = [...state.list.data, action.payload.data]
      const list = utils.formatData({ data })
      return {
        ...state,
        isLoading: false,
        list,
      }
    }

    case type.UPDATE_THREAD: {
      const index = state.list.data.findIndex(
        thread => thread.id === Number(action.payload.id)
      )

      if (index !== -1) {
        state.list.data[index] = {
          ...action.payload.data,
          id: Number(action.payload.id),
        }
      }

      const list = utils.formatData({ data: state.list.data })
      return { ...state, isLoading: false, list }
    }

    case type.DELETE_THREAD: {
      const data = state.list.data.filter(
        thread => thread.id !== action.payload.id
      )

      const list = utils.formatData({ data })

      return {
        ...state,
        isLoading: false,
        list,
      }
    }

    case type.THREAD_FAILED:
      const errors = utils.addError(state.errors, action)
      return {
        ...state,
        errors: {
          ...errors,
        },
        isLoading: false,
      }

    case type.CLEAR_CURRENT_ERROR_THREAD: {
      return {
        ...state,
        errors: {
          ...state.errors,
          current: {},
        },
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default threadReducer
