import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
    secondary: {
      main: '#e6e6e6',
    },
    third: {
      main: '#677b86',
    },
    fourth: {
      main: '#0203DB',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f4f4f4',
    },
  },
})

export default theme
