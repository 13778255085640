import type from './weather.types'

export const addError = (errors, action) => {
  const errorId = errors.data.findIndex(
    error => error.name === action.payload.name
  )

  errors.current = action.payload

  if (errorId === -1) errors.data.push(action.payload)
  else errors.data[errorId] = action.payload

  return errors
}

export const updateWeather = (list, errors, action) => {
  const updateWeatherId = list.findIndex(
    weather => weather.id === action.payload.id
  )
  if (updateWeatherId === -1)
    errors.push({
      name: type.UPDATE_WEATHER,
      error: `${updateWeatherId} don't exist`,
    })
  else list[updateWeatherId] = action.payload
  return { list, errors }
}
