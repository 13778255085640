import type from './source.types'

export const setCurrentSource = source => ({
  type: type.SET_CURRENT_SOURCE,
  payload: source,
})

export const setCurrentSourceRequested = source => ({
  type: type.SET_CURRENT_SOURCE_REQUESTED,
  payload: source,
})

export const setListSource = sources => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: sources,
})

export const addSource = source => ({
  type: type.ADD_SOURCE_REQUESTED,
  payload: source,
})

export const updateSource = source => ({
  type: type.UPDATE_SOURCE_REQUESTED,
  payload: source,
})

export const deleteSource = id => ({
  type: type.DELETE_SOURCE_REQUESTED,
  payload: id,
})

export const sourceFailed = error => ({
  type: type.SOURCE_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_SOURCE,
})
