import type from './user.types'

const rolesOfUsers = [
    { id: 0, name: 'Admin'},
    { id: 1, name: 'Editor'},
    { id: 2, name: 'User'},
]

export const addError = (errors, action) => {
  const errorId = errors.data.findIndex(
    error => error.name === action.payload.name
  )

  errors.current = action.payload

  if (errorId === -1) errors.data.push(action.payload)
  else errors.data[errorId] = action.payload

  return errors
}

export const updateUser = (list, errors, action) => {
  let userData = action.payload;
  const updateUserBodyIndex = list.body.findIndex(user => user.rowId === userData.local_id)
  const updateUserDataIndex = list.data.findIndex(user => user.local_id === userData.local_id)
  if (updateUserBodyIndex === -1 || updateUserDataIndex === -1)
    errors.push({
      name: type.UPDATE_USER,
      error: `${updateUserBodyIndex} don't exist`,
    })
  else {
    let rol = rolesOfUsers.find(rol => rol.id == userData.rol);
    list.body[updateUserBodyIndex] = {
      ...list.body[updateUserBodyIndex],
      col1: userData.name,
      col2: userData.lastname,
      col3: userData.email,
      col4: rol.name,
    };
    list.data[updateUserDataIndex] = {
      ...list.data[updateUserDataIndex],
      email: userData.email,
      emailVerified: userData.isEmailVerified,
      lastname: userData.lastname,
      name: userData.name,
      rol: userData.rol
    };
    return { list, errors }
  }
}

export const formatData = payload => {
  const data = payload.data.map((row, key) => {
    let rolIndex = rolesOfUsers.findIndex(rol => rol.id == row.rol);
    const rolUser = rolIndex > -1 ?
        rolesOfUsers[rolIndex] : rolesOfUsers[2]

    return {
      id: key,
      rowId: row.local_id,
      col1: row.name,
      col2: row.lastname,
      col3: row.email,
      col4: rolUser.name,
    }
  })

  const header = [
    { field: 'id', hide: true },
    { field: 'rowId', headerName: 'id', width: 200 },
    { field: 'col1', headerName: 'Name', width: 200 },
    { field: 'col2', headerName: 'LastName', width: 200 },
    { field: 'col3', headerName: 'Email', width: 250 },
    { field: 'col4', headerName: 'Rol', width: 250 },
  ]

  const body = [...data]

  return {
    data: [...payload.data],
    header,
    body,
    limit: payload.limit,
    page: payload.page,
    total: payload.total,
  }
}
