import type from './article.types'

export const addError = (errors, action) => {
  const errorId = errors.data.findIndex(
    error => error.name === action.payload.name
  )

  errors.current = action.payload

  if (errorId === -1) errors.data.push(action.payload)
  else errors.data[errorId] = action.payload

  return errors
}

export const addArticle = (article, articlesList) => {
  const currentArticleIndex = articlesList.data.findIndex(
    ele => ele.id === article.id
  )
  const bodyIndex = articlesList.body.findIndex(ele => ele.rowId === article.id)

  if (bodyIndex !== -1) {
    if (
      article.content.thread[0] !==
      articlesList.data[currentArticleIndex].content.thread[0]
    ) {
      const filteredBody = articlesList.body.filter(
        ele => ele.rowId !== article.id
      )
      articlesList.body = filteredBody
    } else {
      articlesList.body[bodyIndex] = {
        ...articlesList.body[bodyIndex],
        col1: article?.content?.headline,
        col2: article?.authors,
        col3: article?.source?.name,
        col4: article?.content?.thread.join(', '),
        col5: article?.status,
        col6: article?.date,
      }
    }
  } else {
    articlesList.body.push({
      id: article?.id,
      rowId: article?.id,
      col1: article?.content?.headline,
      col2: article?.authors,
      col3: article?.source?.name,
      col4: article?.content?.thread.join(', '),
      col5: article?.status,
      col6: article?.date,
    })
  }
  if (currentArticleIndex !== -1) {
    articlesList.data[currentArticleIndex] = article
  } else {
    articlesList.data.push(article)
  }

  return articlesList
}

export const updateArticle = (list, errors, action) => {
  const updateArticleId = list.findIndex(user => user.id === action.payload.id)
  if (updateArticleId === -1)
    errors.push({
      name: type.UPDATE_ARTICLE,
      error: `${updateArticleId} don't exist`,
    })
  else list[updateArticleId] = action.payload
  return { list, errors }
}

export const formatData = payload => {
  const data = payload.data.articles.map(row => {
    return {
      id: row?.id,
      rowId: row?.id,
      col1: row?.source == "Twitter" ? row?.lead_paragraph : row?.headline,
      col2: row?.authors,
      col3: row?.source,
      col4: row?.threads,
      col5: row?.status,
      col6: row?.datetime,
    }
  })

  const header = [
    { field: 'id', hide: true },
    { field: 'rowId', headerName: 'id' },
    { field: 'col1', headerName: 'Title / Text', width: 200 },
    { field: 'col2', headerName: 'Author', width: 200 },
    { field: 'col3', headerName: 'Source', width: 100 },
    { field: 'col4', headerName: 'Threads', width: 200 },
    { field: 'col5', headerName: 'Status', width: 100 },
    { field: 'col6', headerName: 'Date', width: 150 },
  ]

  const body = [...data]

  return {
    data: [...payload.data.articles],
    header,
    body,
    limit: payload.limit,
    page: payload.page,
    total: payload.total,
  }
}
