import { all, call } from 'redux-saga/effects'
import { sagas } from './modules'

export default function* rootSaga() {
  yield all([
    call(sagas.user.userSagas),
    call(sagas.thread.threadSagas),
    call(sagas.article.articleSagas),
    call(sagas.location.locationSagas),
    call(sagas.timeline.timelineSagas),
    call(sagas.weather.weatherSagas),
    call(sagas.source.sourceSagas),
    call(sagas.cluster.clusterSagas),
    call(sagas.token.tokenSagas),
  ])
}
