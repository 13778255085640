import type from './article.types'

export const setCurrentArticle = article => ({
  type: type.SET_CURRENT_ARTICLE,
  payload: article,
})

export const setListArticle = articles => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: articles,
})

export const getArticleById = id => ({
  type: type.GET_ARTICLE_BY_ID,
  payload: id,
})

export const setSoucesArticleList = sources => ({
  type: type.ARTICLES_SOURCE_REQUESTED,
  payload: sources,
})

export const addArticle = article => ({
  type: type.ADD_ARTICLE_REQUESTED,
  payload: article,
})

export const addArticleCrawler = linkData => ({
  type: type.ADD_ARTICLE_REQUESTED_CRAWLER,
  payload: linkData,
})

export const updateArticle = article => ({
  type: type.UPDATE_ARTICLE_REQUESTED,
  payload: article,
})

export const deleteArticle = article_id => ({
  type: type.DELETE_ARTICLE_REQUESTED,
  payload: article_id,
})

export const articleStatusDelete = (status) => ({
    type: type.DELETE_ARTICLE,
    payload: status,
})

export const articleFailed = error => ({
  type: type.ARTICLE_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_ARTICLE,
})
