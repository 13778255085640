import rootReducer from './root-reducer'
import rootSaga from './root-saga'

import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
//import thunk from 'redux-thunk';
//import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

// Import the necessary methods for saving and loading
import { save, load } from 'redux-localstorage-simple'
import storage from 'redux-persist/lib/storage'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [
  sagaMiddleware,
  save({ namespace: 'threader_app', debounce: 100 }),
]

if (process.env.NODE_ENV === 'development') {
  //middlewares.push(logger)
}

const persistConfig = {
  timeout: 1000, //Set the timeout function to 2 seconds
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  load({ namespace: 'threader_app' }),
  composeWithDevTools(applyMiddleware(...middlewares))
)
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default store
