export const NAME = 'timeline' // same as the  global state

const types = {
  TIMELINE_TRY: `[${NAME}]/TIMELINE_TRY`,

  SET_CURRENT_TIMELINE: `[${NAME}]/SET_CURRENT_TIMELINE`,
  SET_CURRENT_TIMELINE_REQUESTED: `[${NAME}]/SET_CURRENT_TIMELINE_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_TIMELINE: `[${NAME}]/ADD_TIMELINE`,
  ADD_TIMELINE_REQUESTED: `[${NAME}]/ADD_TIMELINE_REQUESTED`,

  UPDATE_TIMELINE: `[${NAME}]/UPDATE_TIMELINE`,
  UPDATE_TIMELINE_REQUESTED: `[${NAME}]/UPDATE_TIMELINE_REQUESTED`,

  DELETE_TIMELINE: `[${NAME}]/DELETE_TIMELINE`,
  DELETE_TIMELINE_REQUESTED: `[${NAME}]/DELETE_TIMELINE_REQUESTED`,

  TIMELINE_FAILED: `[${NAME}]/TIMELINE_FAILED`,
  CLEAR_CURRENT_ERROR_TIMELINE: `[${NAME}]/CLEAR_CURRENT_ERROR_TIMELINE`,
}

export default types
