import { Grid, Button, makeStyles, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import { selectors, actions } from '../../state/modules'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& button': {
      margin: theme.spacing(1),
    },
  },
  cover: {
    width: '100%',
    height: 400,
    marginLeft: '.5rem',
    marginTop: '2rem',
  },
}))

function LocationPageDetail({
  setCurrentLocation,
  currentLocation,
  addLocation,
  updateLocation,
  deleteLocation,
}) {
  const classes = useStyles()
  const [size, setSize] = useState()
  const pageName = 'Location'
  const [modal, setModal] = useState({ ...defaultDataModal })

  const navigate = useNavigate()
  const { id } = useParams()

  const [location, setLocation] = useState({
    city: '',
    country: '',
    longitude: '',
    latitude: '',
  })

  const handleSizeChange = size => {
    setSize(size)
  }

  useEffect(() => {
    if (id !== 'null') setLocation(currentLocation)
  }, [])

  const handleDeleteSubmit = () => {
    setModal({
      open: true,
      title: 'Notice!',
      description: `"${location.city} of ${location.country} " will be delete`,
      type: 'delete',
      id,
    })
  }

  const handleClickBack = ({ pathname }) => {
    setCurrentLocation(null)
    navigate(`/${pathname[0]}`)
  }

  const handleSubmit = event => {
    event.preventDefault()
    const type = id === 'null' ? 'add' : 'update'
    setModal({
      open: true,
      title: 'Notice!',
      description: `"${location.city} of ${location.country} " will be ${type}`,
      type,
      id,
    })
  }

  const handleCloseModal = () => {
    setModal({ ...defaultDataModal, open: false })
  }

  const handleAccept = id => {
    //
    if (modal.type === 'delete') {
      deleteLocation(id)
    } else if (modal.type === 'add') {
      addLocation(location)
    } else if (modal.type === 'update') {
      updateLocation({ id, data: location })
    }
    navigate(`/${pageName.toLowerCase()}`)
  }

  return (
    <Layout
      hideBreadcrum
      showBackBtn
      onClickBack={handleClickBack}
      onSizeChange={handleSizeChange}
    >
      <form
        className={classes.root}
        style={{ width: size === 'xl' ? '60%' : '100%' }}
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <TextField
          required
          id='location-country'
          label='Country'
          placeholder='(Country name)'
          value={location.country}
          onInput={e => setLocation({ ...location, country: e.target.value })}
        />

        <TextField
          required
          id='location-city'
          label='City'
          placeholder='(City name)'
          value={location.city}
          onInput={e => setLocation({ ...location, city: e.target.value })}
        />

        <TextField
          required
          type='number'
          id='location-latitude'
          label='Latitude'
          placeholder='(Latitude name)'
          value={location.latitude}
          onInput={e => setLocation({ ...location, latitude: e.target.value })}
        />

        <TextField
          required
          type='number'
          id='location-longitude'
          label='Longitude'
          placeholder='(Longitude name)'
          value={location.longitude}
          onInput={e => setLocation({ ...location, longitude: e.target.value })}
        />

        <Grid container justify='flex-end'>
          {id !== 'null' && (
            <Button
              size='large'
              variant='outlined'
              onClick={handleDeleteSubmit}
            >
              Delete
            </Button>
          )}

          <Button
            size='large'
            variant='contained'
            disableElevation
            color='primary'
            type='submit'
          >
            Save
          </Button>
        </Grid>

        <ServerModal
          isOpen={modal.open}
          title={modal.title}
          description={modal.description}
          id={modal.id}
          showBtns
          onAccept={handleAccept}
          onClose={handleCloseModal}
        />
      </form>
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  locations: selectors.location.selectLocationList,
  currentLocation: selectors.location.selectCurrentLocation,
  locationLoading: selectors.location.selectLocationLoading,
})

const mapDispatchToProps = dispatch => ({
  setCurrentLocation: location =>
    dispatch(actions.location.setCurrentLocation(location)),
  addLocation: location => dispatch(actions.location.addLocation(location)),
  updateLocation: location =>
    dispatch(actions.location.updateLocation(location)),
  deleteLocation: id => dispatch(actions.location.deleteLocation(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationPageDetail)
