import { all, put, takeLatest } from 'redux-saga/effects'
import type from './weather.types'
import Service from '../../../services'

const Ser = Service()
const apiGet = new Ser.API_GET()
const URL = process.env.URL_API_JSON_API

export function* tryWeatherList() {
  try {
    //const response = yield call(service.apisauce.get, '/weathers')
    const response = yield apiGet.getWeather()
    //const payload = yield call([response, 'json'])
    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.WEATHER_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchWeatherList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryWeatherList)
}

const apiGetParser = new Ser.API_GET(process.env.URL_API_PARSER)
//console.log('parserAPI', parserAPI)

function* tryCurrentWeather(action) {
  console.log('++++++++++ setCurrentWeather weather', action)
  yield put({ type: type.WEATHER_TRY })
  try {
    //const response = yield call(parserAPI.apisauce.get, '/weathers')
    //
    const response = yield apiGetParser.getWeather(
      'https://www.washingtonpost.com/health/2020/11/23/coronavirus-cases-deaths-hospitalizatons-lag/',
      'covid-19'
    )

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.SET_CURRENT_WEATHER, payload })
  } catch (error) {
    yield put({
      type: type.WEATHER_FAILED,
      payload: { name: type.SET_CURRENT_WEATHER, error: error.message },
    })
  }
}

export function* watchCurrentWeather() {
  yield takeLatest(type.SET_CURRENT_WEATHER_REQUESTED, tryCurrentWeather)
}

export function* addWeather(action) {
  try {
    const response = yield fetch(`${URL}/weathers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    })

    const newWeather = yield response.json()

    yield put({
      type: type.ADD_WEATHER,
      payload: newWeather.data,
    })
  } catch (error) {
    yield put({
      type: type.WEATHER_FAILED,
      payload: { name: type.ADD_WEATHER, error: error.message },
    })
  }
}

export function* watchAddWeather() {
  yield takeLatest(type.ADD_WEATHER_REQUESTED, addWeather)
}

export function* deleteWeather(action) {
  yield put({ type: type.WEATHER_TRY })
  try {
    console.log('++++++++++ delete weather', action)
    const response = yield fetch(`${URL}/weathers/` + action.payload, {
      method: 'DELETE',
    })

    const deletedWeather = yield response.json()

    yield put({
      type: type.DELETE_WEATHER,
      //payload: action.payload,
      payload: deletedWeather,
    })
  } catch (error) {
    yield put({
      type: type.WEATHER_FAILED,
      payload: { name: type.DELETE_WEATHER, error: error.message },
    })
  }
}

export function* watchDeleteWeather() {
  yield takeLatest(type.DELETE_WEATHER_REQUESTED, deleteWeather)
}

export function* updateWeather(action) {
  try {
    const response = yield fetch(`${URL}/weathers/` + action.payload.id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    })

    const updatedWeather = yield response.json()

    yield put({
      type: type.UPDATE_WEATHER,
      payload: updatedWeather.data,
    })
  } catch (error) {
    yield put({
      type: type.WEATHER_FAILED,
      payload: { name: type.UPDATE_WEATHER, error: error.message },
    })
  }
}

export function* watchUpdateWeather() {
  yield takeLatest(type.UPDATE_WEATHER_REQUESTED, updateWeather)
}

export function* weatherSagas() {
  yield all([
    //call(tryWeatherList),
    //call(tryCurrentWeather),
    watchWeatherList(),
    watchCurrentWeather(),
    watchAddWeather(),
    watchDeleteWeather(),
    watchUpdateWeather(),
  ])
}
