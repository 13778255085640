import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import TableGrid from '../../components/table.grid/table.grid.component'
import {actions, selectors, user} from '../../state/modules'
import { useNavigate } from 'react-router-dom'
import { getParam } from './articles.utils'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'

function Article({
  articles,
  articlesLoading,
  threads,
  loadArticles,
  setCurrentArticle,
  currentArticleError,
  clearCurrenArticleError,
  deleteArticle,
  statusDelete,
  articleStatusDelete,
  currentUserAuth
}) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(0)
  const [modal, setModal] = useState({ ...defaultDataModal })
  const [selectArticle, setSelectArticles] = useState([])
  const [orderBy] = useState({ field: 'id', directionOrder: 'desc' })
  const [currentThread, setCurrentThread] = useState({})
  const navigate = useNavigate()
  const pageName = 'Article'
  const paramThread = useRef(null)
  const rolUser = currentUserAuth?.rol > -1 ? currentUserAuth.rol : 3

  useEffect(() => {
    paramThread.current = getParam('thread')
  },[])

  useEffect(() => {
    if (statusDelete){
      setTimeout(() => {
          loadArticles(currentThread.id, limit, (page * limit) + 1, orderBy.field, orderBy.directionOrder)
          articleStatusDelete(false)
      }, 2000)
    }
  },[statusDelete])

  useEffect(() => {

    if (paramThread?.current) {
      let thread = threads.data.find(thread => thread.id === paramThread.current)
      setCurrentThread(thread)
    } else {
      setCurrentThread(threads.data[0])
    }
  }, [paramThread?.current, threads.data.length])

  useEffect(() => {
    if (currentArticleError.name) {
      setModal({
        open: true,
        title: 'Error!',
        description: `${currentArticleError.error}`,
        type: 'error',
        id: null,
      })
    }
  }, [currentArticleError.name])

  const handleClickAddNew = () => {
    setCurrentArticle(null)
    const thread = btoa(JSON.stringify(currentThread.id))
    navigate(`/${pageName.toLowerCase()}/detail/null/?thread=${thread}`)
  }

  const handlePage = params => {
    //console.log('handlePage', currentThread)
    const currentPage = params.page + 1
    setPage(currentPage)
    setLimit(params.pageSize)
    loadArticles(currentThread.id, params.pageSize, (params.page  *  limit) + 1, orderBy.field, orderBy.directionOrder)
  }

  const handleChangeThread = value => {
    if (currentThread !== value || paramThread?.current) {
      let valueThread =  value !== "" ? value : paramThread?.current
      //console.log('handlePage', currentThread, value)
      const thread = threads.data.find(thread => thread.name === valueThread) || {
        ...currentThread,
      }

      setCurrentThread(thread)
      setLimit(50)
      setPage(1)
      loadArticles(thread.id, 50, 1, "id", "desc")
    }
  }

  const onEditHandler = row => {
    setCurrentArticle(null)

    const thread = btoa(JSON.stringify(currentThread.id))
    navigate(`/${pageName.toLowerCase()}/detail/${row.rowId}/?thread=${thread}`)
  }

  const handleCloseModal = () => {
    setModal({ ...defaultDataModal, open: false })
  }

  // const onDelete = async (articleId) => await deleteArticle(articleId)

  const handleAccept = async () => {
    if (modal.type === 'error') {
      setModal({ ...defaultDataModal, open: false })
      clearCurrenArticleError()
    }
    if (modal.type === 'delete') {
        new Promise(() => { deleteArticle(selectArticle) }).then(()=> {
            return console.log('Delete all')
        })
    }
  }

  const onHandleDelete = () => {
      setModal({
          open: true,
          title: 'Delete Articles',
          description: 'Are you sure you want to delete the articles? This can\'t be undone',
          type: 'delete',
          id: null,
      })
  }

  const onChangeOrderBy = (orderBy, directionOrderBy) => {
    loadArticles(currentThread.id, limit, (page *  limit) + 1, orderBy, directionOrderBy)
  }

  return (
    <Layout>
      <TableGrid
        name={'Article'}
        rows={articles.body}
        columns={articles.header}
        loading={articlesLoading}
        type={true}
        showRemoveBtn={true}
        valueOption={currentThread}
        limit={limit}
        total={limit * articles.total}
        options={threads.data
          .filter(thread => thread.status)
          .map(thread => thread.name)}
        loadServerRows={handlePage}
        onChangeType={(value, inputValue) =>
          handleChangeThread(value, inputValue)
        }
        onClickAddNew={handleClickAddNew}
        onEdit={onEditHandler}
        showDeleteBtn={false}
        actionsWidth={120}
        checkboxSelection={true}
        onSelectionModelChange={(id)=>{
            if (id.selectionModel?.length > 0) {
              let articleListId = id.selectionModel.map(item => parseInt(item))
              setSelectArticles(articleListId)
            }
        }}
        rolUser={rolUser}
        onDelete={onHandleDelete}
        onOrderByChange={ onChangeOrderBy }
      />
      <ServerModal
        isOpen={modal.open}
        title={modal.title}
        description={modal.description}
        id={modal.id}
        showBtns
        onAccept={handleAccept}
        onClose={handleCloseModal}
      />
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  articles: selectors.article.selectArticleList,
  articlesLoading: selectors.article.selectArticleLoading,
  threads: selectors.thread.selectThreadList,
  currentArticleError: selectors.article.selectArticleCurrentError,
  statusDelete: selectors.article.selectArticleDelete,
  currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
  loadArticles: (thread_id, limit, offset, order_by, direction_order_by) =>
    dispatch(actions.article.setListArticle({ thread_id, limit, offset,  order_by, direction_order_by })),
  setCurrentArticle: article =>
    dispatch(actions.article.setCurrentArticle(article)),
  clearCurrenArticleError: () => dispatch(actions.article.clearCurrentError()),
  deleteArticle: articleId => dispatch(actions.article.deleteArticle(articleId)),
  articleStatusDelete: status => dispatch(actions.article.articleStatusDelete(status))
})

export default connect(mapStateToProps, mapDispatchToProps)(Article)
