import { createSelector } from 'reselect'
import { NAME } from './thread.types'

export const selectThread = state => state[NAME]

export const selectThreadLoading = createSelector(
  [selectThread],
  thread => thread.isLoading
)

export const selectCurrentThread = createSelector(
  [selectThread],
  thread => thread.currentThread
)

export const selectThreadList = createSelector(
  [selectThread],
  thread => thread.list
)

export const selectThreadErrors = createSelector(
  [selectThread],
  thread => thread.errors
)

export const selectThreadCurrentError = createSelector(
  [selectThreadErrors],
  error => error.current
)
