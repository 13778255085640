import React from 'react'
import {
  ListItemIcon,
  ListItem,
  ListItemText,
  Divider,
  List,
} from '@material-ui/core'
import AcUnitIcon from '@material-ui/icons/AcUnit'
//import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import LinkIcon from '@material-ui/icons/Link'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ClusterIcon from '@material-ui/icons/Web'
import { useNavigate, useLocation } from 'react-router-dom'


const menuElements = [
  { id: 1, name: 'Threads', link: '/thread', position: 'main' },
  { id: 2, name: 'Articles / Tweets', link: '/article', position: 'main' },
  { id: 3, name: 'Dashboard', link: '/dashboard', position: 'secondary' },
  { id: 4, name: 'Users', link: '/users', position: 'secondary' },
  { id: 5, name: 'Analytics', link: null, position: 'secondary' },
  { id: 6, name: 'Token Devices', link: '/tokens', position: 'secondary' },
  { id: 7, name: 'Clusters', link: '/cluster', position: 'main' },
  { id: 8, name: 'Sources', link: '/source', position: 'main' },
  { id: 9, name: 'Locations', link: '/location', position: 'main' },
  
]

const Menu = React.forwardRef((props, ref) => {
  const navigate = useNavigate()
  const location = useLocation()
  const rol = props.rol >=0 ? props.rol : 2

  const handleClick = id => {
    const index = menuElements.findIndex(el => el.id === id)
    if (index !== -1 && menuElements[index].link)
      navigate(menuElements[index].link)
  }

  const currentPath = location.pathname;

  let elements =  menuElements.filter(item => {
    if (rol == 0)
        return true
    if (rol == 1 || rol == 2)
          return item.id == 2 || item.id == 3 ? true : false
  })

  return (
    <>
      <Divider />
      <List ref={ref}>
        {elements
          .filter(el => el.position === 'main')
          .map((item, index) => (
            <ListItem
              button
              selected={currentPath === item.link}
              key={item.name + index}
              onClick={() => handleClick(item.id)}
            >
              <ListItemIcon>
                {item.id === 1 && <AcUnitIcon />}
                {item.id === 2 && <DescriptionOutlinedIcon />}
                {item.id === 7 && <ClusterIcon />}
                {item.id === 8 && <FindInPageIcon />}
                {item.id === 9 && <LocationOnIcon />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
      </List>
      <Divider />
      <List>
        {elements
          .filter(el => el.position === 'secondary')
          .map(item => (
            <ListItem
              button
              selected={currentPath === item.link}
              key={item.name}
              onClick={() => handleClick(item.id)}
            >
              <ListItemIcon>
                {item.id === 3 && <DashboardIcon />}
                {item.id === 4 && <PeopleAltOutlinedIcon />}
                {item.id === 5 && <AssessmentOutlinedIcon />}
                {item.id === 6 && <LinkIcon />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
      </List>
    </>
  )
})


export default Menu
