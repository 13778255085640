import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import TableGrid from '../../components/table.grid/table.grid.component'
import {actions, selectors, user} from '../../state/modules'
import { useNavigate } from 'react-router-dom'
import {defaultDataModal} from "../../components/modal/modal.utils";
import ServerModal from '../../components/modal/modal.component'

function UserPage({
  users,
  loadingUsers,
  loadUsers,
  deleteUser,
  currentUserAuth
}) {
  const navigate = useNavigate()
  const [selectUsers, setSelectUsers] = useState([])
  const [modal, setModal] = useState({ ...defaultDataModal })
  const rolUser = currentUserAuth?.rol > -1 ? currentUserAuth.rol : 3

  useEffect(() => {
    if (rolUser != 0) {
      return navigate(`/`)
    }
    loadUsers()
  },[])

  const handleClickAddNew = () => {
    navigate(`/users/detail/null`)
  }

  const onEditHandler = row => {
    return navigate(`/users/detail/${row.rowId}`)
  }

  const deleteUsers = (usersId) => {
      usersId.forEach(user_id => {
          setTimeout(() => {
              deleteUser({
                  user_uid: users.data[user_id].local_id,
                  user_id: users.data[user_id].id
              },)
          }, 2000)

      })
  }

  const handleAccept = async () => {
    if (modal.type === 'delete') {
        new Promise(() => { deleteUsers(selectUsers) }).then(()=> {
            return console.log('Delete all')
        })
    }
  }

  const handleCloseModal = () => {
    setModal({ ...defaultDataModal, open: false })
  }

  const onDeleteHandler = () => {
      setModal({
          open: true,
          title: 'Delete User(s)',
          description: 'Are you sure you want to delete the User(s)? This can\'t be undone',
          type: 'delete',
          id: null,
      })
  }

  return (
    <Layout>
      <TableGrid
        name='Users'
        rows={users.body}
        showRemoveBtn={true}
        checkboxSelection={true}
        showDeleteBtn={false}
        loading={loadingUsers}
        columns={users.header}
        onEdit={onEditHandler}
        onDelete={onDeleteHandler}
        onClickAddNew={handleClickAddNew}
        onSelectionModelChange={(id)=>{
            if (id.selectionModel?.length > 0) {
                let userListId = id.selectionModel.map(item => parseInt(item))
                setSelectUsers(userListId)
            }
        }}
        rolUser={rolUser}
      />
      <ServerModal
        isOpen={modal.open}
        title={modal.title}
        description={modal.description}
        id={modal.id}
        showBtns
        onAccept={handleAccept}
        onClose={handleCloseModal}
      />
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  users: selectors.user.selectUserList,
  loadingUsers: selectors.user.selectUserLoading,
  currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
  loadUsers: () => dispatch(actions.user.setListUser()),
  deleteUser: (user) => dispatch(actions.user.deleteUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
