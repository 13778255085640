import React from 'react'
import Layout from '../../components/layout/layout.component'
import TableGrid from '../../components/table.grid/table.grid.component'

export default function PublicationPage() {
  return (
    <Layout>
      <TableGrid name={'Publication'} />
    </Layout>
  )
}
