import type from './weather.types'
import * as utils from './weather.utils'

export const initialState = {
  currentWeather: null,
  list: [],
  errors: {
    current: {},
    data: [],
  },
  isLoading: null,
}

const weatherReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.WEATHER_TRY:
      return { ...state, isLoading: true }

    case type.SET_CURRENT_WEATHER:
      return {
        ...state,
        currentWeather: action.payload,
        isLoading: false,
      }

    case type.LIST_SUCCESS:
      return { ...state, isLoading: false, list: action.payload }

    case type.ADD_WEATHER:
      return {
        ...state,
        isLoading: false,
        list: [action.payload, ...state.list],
      }

    case type.UPDATE_WEATHER:
      const updatedWeather = utils.updateWeather(
        state.list,
        state.errors,
        action
      )
      return { ...state, ...updatedWeather, isLoading: false }

    case type.DELETE_WEATHER:
      const newWeatherList = state.list.filter(
        weather => weather.id !== action.payload
      )
      return {
        ...state,
        isLoading: false,
        list: newWeatherList,
      }

    case type.WEATHER_FAILED:
      const errors = utils.addError(state.errors, action)

      return { ...state, errors, isLoading: false }

    default:
      return state
  }
}

export default weatherReducer
