import reducer, { initialState } from './location.reducer'
import types from './location.types'

import * as sagas from './location.sagas'
import * as actions from './location.actions'
import * as utils from './location.utils'
import * as selectors from './location.selectors'

export const location = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
