import reducer, { initialState } from './token.reducer'
import types from './token.types'

import * as sagas from './token.sagas'
import * as actions from './token.actions'
import * as utils from './token.utils'
import * as selectors from './token.selectors'

export const token = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
