import reducer, { initialState } from './bookmark.reducer'
import types from './bookmark.types'

import * as sagas from './bookmark.sagas'
import * as actions from './bookmark.actions'
import * as utils from './bookmark.utils'
import * as selectors from './bookmark.selectors'

export const bookmark = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
