import type from './location.types'

export const setCurrentLocation = location => ({
  type: type.SET_CURRENT_LOCATION,
  payload: location,
})

export const setCurrentLocationRequested = location => ({
  type: type.SET_CURRENT_LOCATION_REQUESTED,
  payload: location,
})

export const setListLocation = locations => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: locations,
})

export const addLocation = location => ({
  type: type.ADD_LOCATION_REQUESTED,
  payload: location,
})

export const updateLocation = location => ({
  type: type.UPDATE_LOCATION_REQUESTED,
  payload: location,
})

export const deleteLocation = location_id => ({
  type: type.DELETE_LOCATION_REQUESTED,
  payload: location_id,
})

export const locationFailed = error => ({
  type: type.LOCATION_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_LOCATION,
})
