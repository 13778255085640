import type from './bookmark.types'
import * as utils from './bookmark.utils'

export const initialState = {
  currentBookmark: null,
  list: [],
  errors: {
    current: {},
    data: [],
  },
  isLoading: null,
}

const bookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.BOOKMARK_TRY:
      return { ...state, isLoading: true }

    case type.SET_CURRENT_BOOKMARK:
      return {
        ...state,
        currentBookmark: action.payload,
        isLoading: false,
      }

    case type.LIST_SUCCESS:
      return { ...state, isLoading: false, list: action.payload }

    case type.ADD_BOOKMARK:
      return {
        ...state,
        isLoading: false,
        list: [action.payload, ...state.list],
      }

    case type.UPDATE_BOOKMARK:
      const updateBookmark = utils.updateBookmark(
        state.list,
        state.errors,
        action
      )
      return { ...state, ...updateBookmark, isLoading: false }

    case type.DELETE_BOOKMARK:
      const newBookmarkList = state.list.filter(
        bookmark => bookmark.id !== action.payload
      )
      return {
        ...state,
        isLoading: false,
        list: newBookmarkList,
      }

    case type.BOOKMARK_FAILED:
      const errors = utils.addError(state.list, action)
      return { ...state, errors: { ...errors }, isLoading: false }

    case type.CLEAR_CURRENT_ERROR_BOOKMARK: {
      return {
        ...state,
        errors: {
          ...state.errors,
          current: {},
        },
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default bookmarkReducer
