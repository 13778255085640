import type from './timeline.types'

export const addError = (errors, action) => {
  const errorId = errors.findIndex(error => error.name === action.payload.name)
  if (errorId === -1) errors.push(action.payload)
  else errors[errorId] = action.payload
  return errors
}

export const updateTimeline = (list, errors, action) => {
  const updateTimelineId = list.findIndex(
    timeline => timeline.id === action.payload.id
  )
  if (updateTimelineId === -1)
    errors.push({
      name: type.UPDATE_TIMELINE,
      error: `${updateTimelineId} don't exist`,
    })
  else list[updateTimelineId] = action.payload
  return { list, errors }
}
