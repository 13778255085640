import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import TableGrid from '../../components/table.grid/table.grid.component'
import {actions, selectors, user} from '../../state/modules'
import { useNavigate } from 'react-router-dom'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'

function Thread({
  threads,
  setCurrentThread,
  threadsLoading,
  deleteThread,
  currentThreadError,
  clearCurrentThreadError,
  currentUserAuth
}) {
  const navigate = useNavigate()
  const pageName = 'Thread'
  const [modal, setModal] = useState({ ...defaultDataModal })
  const rolUser = currentUserAuth?.rol > -1 ? currentUserAuth.rol : 3

  useEffect(() => {
      if (rolUser != 0) {
          return navigate(`/`)
      }
  },[])

  const handleClickAddNew = () => {
    navigate(`/${pageName.toLowerCase()}/detail/null`)
  }

  const onEditHandler = row => {
    const current = threads.data.find(thread => thread.id === row.rowId)
    if (current) {
      setCurrentThread(current)
      return navigate(`/${pageName.toLowerCase()}/detail/${row.rowId}`)
    }
    return alert(`${row.id} don't exist`)
  }

  const onDeleteHandler = row => {
    setModal({
      open: true,
      title: 'Notice!',
      description: `"${row.col1} #${row.rowId}" will be delete`,
      type: 'delete',
      id: row.rowId,
    })
  }

  const handleCloseModal = () => {
    setModal({ ...defaultDataModal, open: false })
  }

  const handleAccept = id => {
    if (modal.type === 'delete') {
      deleteThread(id)
    } else if (modal.type === 'error') {
      setModal({ ...defaultDataModal, open: false })
      clearCurrentThreadError()
    }
  }

  useEffect(() => {
    if (currentThreadError.name) {
      setModal({
        open: true,
        title: 'Error!',
        description: `${currentThreadError.error}`,
        type: 'error',
        id: null,
      })
    }
  }, [currentThreadError.name])

  return (
    <Layout>
      <TableGrid
        name={pageName}
        rows={threads.body}
        columns={threads.header}
        loading={threadsLoading}
        limit={25}
        onEdit={onEditHandler}
        onDelete={onDeleteHandler}
        onClickAddNew={handleClickAddNew}
        rolUser={rolUser}
      />
      <ServerModal
        isOpen={modal.open}
        title={modal.title}
        description={modal.description}
        id={modal.id}
        showBtns
        onAccept={handleAccept}
        onClose={handleCloseModal}
      />
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  threads: selectors.thread.selectThreadList,
  threadsLoading: selectors.thread.selectThreadLoading,
  currentThreadError: selectors.thread.selectThreadCurrentError,
  currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
  setCurrentThread: thread => dispatch(actions.thread.setCurrentThread(thread)),
  deleteThread: threadId => dispatch(actions.thread.deleteThread(threadId)),
  clearCurrentThreadError: () => dispatch(actions.thread.clearCurrentError()),
})

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Thread))
