import type from './user.types'
import * as utils from './user.utils'

export const initialState = {
  currentUser: null,
  currentUserAuth: null,
  list: {
    data: [],
    body: [],
    limit: 10,
    page: 1,
    total: 1,
  },
  errors: {
    current: {},
    data: [],
  },
  isLoading: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.USER_TRY:
      return {
        ...state,
        isLoading: true,
      }

    case type.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        isLoading: false,
      }

    case type.SET_CURRENT_USER_AUTH:
        return {
            ...state,
            currentUserAuth: action.payload,
            isLoading: false,
        }

    case type.LIST_SUCCESS:
      const list = utils.formatData(action.payload)
      return { ...state, isLoading: false, list }

    case type.ADD_USER:
      return {
        ...state,
        isLoading: false,
        list: [action.payload, ...state.list],
      }

      case type.UPDATE_USER:
        const updateUser = utils.updateUser(state.list, state.errors, action)
        console.log('updateUser', updateUser)
        const UserList = state.list.data.filter(user => user.id !== action.id)
        const UserBody = state.list.body.filter(user => user.rowId !== action.userId)
        return {
            ...state,
            isLoading: false,
            list: {...state.list,  body: UserBody, data: UserList }
        }


    case type.DELETE_USER:
      const newUserList = state.list.data.filter(user => user.id !== action.payload)
      const newUserBody = state.list.body.filter(user => user.rowId !== action.payload)
      return {
        ...state,
        isLoading: false,
        list: {...state.list,  body: newUserBody, data: newUserList }
      }

    case type.USER_FAILED:
      const errors = utils.addError(state.errors, action)
      return { ...state, errors: { ...errors }, isLoading: false }

    case type.CLEAR_CURRENT_ERROR_USER: {
      return {
        ...state,
        errors: {
          ...state.errors,
          current: {},
        },
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default userReducer
