import type from './cluster.types'

export const addError = (errors, action) => {
  const errorId = errors.data.findIndex(
    error => error.name === action.payload.name
  )

  errors.current = action.payload

  if (errorId === -1) errors.data.push(action.payload)
  else errors.data[errorId] = action.payload

  return errors
}

export const updateCluster = (list, errors, action) => {
  const updateClusterId = list.findIndex(
    cluster => cluster.id === action.payload.id
  )
  if (updateClusterId === -1)
    errors.push({
      name: type.UPDATE_CLUSTER,
      error: `${updateClusterId} don't exist`,
    })
  else list[updateClusterId] = action.payload
  return { list, errors }
}

export const formatData = payload => {
  const data = payload.data.map(row => {
    let source = row?.articles[0].source?.name
    let firstArticle = row?.articles[0]
    return {
      id: row?.id,
      rowId: row?.id,
      col1: new Date(row?.datetime).toUTCString(),
      col2: row?.articles.length,
      col3: source && source == "Twitter" ? firstArticle?.content?.lead_paragraph : firstArticle?.content?.headline
    }
  })

  const header = [
    { field: 'id', hide: true },
    { field: 'rowId', headerName: 'id', width: 80 },
    { field: 'col1', headerName: 'Datetime', width: 280 },
    { field: 'col2', headerName: 'Count', width: 100 },
    { field: 'col3', headerName: 'Title / Text', width: '40%'},
  ]

  const body = [...data]

  return {
    data: [...payload.data],
    header,
    body,
    limit: 50,
    page: 1,
    total: 1,
  }
}
