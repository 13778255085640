import { createSelector } from 'reselect'
import { NAME } from './timeline.types'

export const selectTimeline = state => state[NAME]

export const selectTimelineLoading = createSelector(
  [selectTimeline],
  user => user.isLoading
)

export const selectCurrentTimeline = createSelector(
  [selectTimeline],
  user => user.currentTimeline
)

export const selectTimelineList = createSelector(
  [selectTimeline],
  user => user.list
)
