import { createSelector } from 'reselect'
import { NAME } from './weather.types'

export const selectWeather = state => state[NAME]

export const selectWeatherLoading = createSelector(
  [selectWeather],
  weather => weather.isLoading
)

export const selectCurrentWeather = createSelector(
  [selectWeather],
  weather => weather.currentWeather
)

export const selectWeatherList = createSelector(
  [selectWeather],
  weather => weather.list
)
