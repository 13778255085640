import type from './token.types'

export const setCurrentToken = token => ({
  type: type.SET_CURRENT_TOKEN,
  payload: token,
})

export const setCurrentTokenRequested = token => ({
  type: type.SET_CURRENT_TOKEN_REQUESTED,
  payload: token,
})

export const setListToken = tokens => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: tokens,
})

export const deleteToken = id => ({
  type: type.DELETE_TOKEN_REQUESTED,
  payload: id,
})

export const tokenFailed = error => ({
  type: type.TOKEN_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_CLUSTER,
})
