import { all, put, takeLatest } from 'redux-saga/effects'
import type from './article.types'
import { API_GET, API_POST, API_DELETE} from '../../../services'

const apiGet = new API_GET()
const apiPost = new API_POST()
const apiDelete = new API_DELETE()
const apiGetCrawler = new API_GET(process.env.REACT_APP_URL_API_PARSER)

export function* tryArticlesList(action) {
  try {
    const {
      payload: { thread_id, limit, offset, order_by, direction_order_by },
    } = action

    yield put({ type: type.ARTICLE_TRY })

    const response = yield apiGet.getArticles(thread_id, limit, offset, order_by, direction_order_by)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.ARTICLE_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchArticleList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryArticlesList)
}

export function* tryGetArticleById(action) {
  try {
    const { payload: { id }
    } = action

    yield put({ type: type.ARTICLE_TRY })

    const response = yield apiGet.getArticlesById(id)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data.data

    yield put({ type: type.SET_CURRENT_ARTICLE, payload })
  } catch (error) {
    yield put({
      type: type.ARTICLE_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchArticleById() {
  yield takeLatest(type.GET_ARTICLE_BY_ID, tryGetArticleById)
}

export function* addArticle(action) {
  yield put({ type: type.ARTICLE_TRY })
  try {
    const response = yield apiPost.addArticle(action.payload)

    if (!response.ok) throw new Error(response.problem)

    const newArticle = yield response.data

    yield put({
      type: type.ADD_ARTICLE,
      payload: newArticle.data,
    })
  } catch (error) {
    yield put({
      type: type.ARTICLE_FAILED,
      payload: { name: type.ADD_ARTICLE, error: error.message },
    })
  }
}

export function* watchAddArticle() {
  yield takeLatest(type.ADD_ARTICLE_REQUESTED, addArticle)
}

export function* addArticleCrawler(action) {
  try {
    yield put({ type: type.ARTICLE_TRY })

    const response = yield apiGetCrawler.getArticleCrawler(action.payload)

    if (!response.ok) throw new Error(response.problem)

    const newArticle = yield response.data

    yield put({
      type: type.ADD_ARTICLE,
      payload: newArticle.data,
    })

    yield put({
      type: type.SET_CURRENT_ARTICLE,
      payload: newArticle.data,
    })
  } catch (error) {
    yield put({
      type: type.ARTICLE_FAILED,
      payload: { name: type.ADD_ARTICLE, error: error.message },
    })
  }
}

export function* watchAddArticleCrawler() {
  yield takeLatest(type.ADD_ARTICLE_REQUESTED_CRAWLER, addArticleCrawler)
}

export function* deleteArticle(action) {
  try {
    yield put({ type: type.ARTICLE_TRY })

    let error = false
    let message = ''
    yield action.payload.forEach(articleId => {
        apiDelete.deleteArticle(articleId).then(response => {
            if (!response.ok) {
                error = true
                message = response.data.detail
            }
        })

    })

    if (!error)
      yield put({
          type: type.DELETE_ARTICLE,
          payload: true,
      })
    else{
        throw new Error(message)
    }

  } catch (error) {
      yield put({
        type: type.ARTICLE_FAILED,
        payload: { name: type.DELETE_ARTICLE, error: error.message },
      })
      yield put({
          type: type.DELETE_ARTICLE,
          payload: false,
      })
  }
}

export function* watchDeleteArticle() {
  yield takeLatest(type.DELETE_ARTICLE_REQUESTED, deleteArticle)
}

//export function* updateArticle(action) {
//try {
//const response = yield fetch(`${URL}/articles/` + action.payload.id, {
//method: 'PUT',
//headers: {
//'Content-Type': 'application/json',
//},
//body: JSON.stringify(action.payload),
//})

//const updatedArticle = yield response.json()

//yield put({
//type: type.UPDATE_ARTICLE,
//payload: updatedArticle.data,
//})
//} catch (error) {
//yield put({
//type: type.ARTICLE_FAILED,
//payload: { name: type.UPDATE_ARTICLE, error: error.message },
//})
//}
//}

//export function* watchUpdateArticle() {
//yield takeLatest(type.UPDATE_ARTICLE_REQUESTED, updateArticle)
//}

export function* articleSagas() {
  yield all([
    //call(tryArticlesList),
    //call(tryCurrentArticle),
    watchArticleList(),
    watchAddArticle(),
    watchDeleteArticle(),
    //watchUpdateArticle(),
    watchAddArticleCrawler(),
    watchArticleById()
  ])
}
