import API from './api.config'

/**
 * GET
 **/

export default class API_GET extends API {
  constructor(baseURL = process.env.URL_API) {
    super(baseURL)
  }

  getUser(id) {
    this.generateApi()
    return this.api.get(`/users/${id}`)
  }

  getUsers() {
    this.generateApi()
    return this.api.get(`/users`)
  }

  /*
   * getThread
   * @param string source_url Url from news
   * @param string main_tag Name of thread
   * */
  getThread(source_url, main_tag) {
    let encodedUrl = btoa(source_url)
    return this.api.get('/', { source_url: encodedUrl, main_tag })
  }

  getThreads() {
    return this.api.get('/threads')
  }

  getSources() {
    return this.api.get('/sources')
  }

  getTokens(
    limit = 100,
    offset = 0,
  ) {
    return this.api.get('/tokens/token_devices', {
      offset,
      limit
    })
  }

  getClusters(
    limit = 100,
    offset = 0,
  ) {
      return this.api.get('/clusters', {
        offset,
        limit
      })
  }

  getLocations(thread_id = null, from_date = null, to_date = null) {
    return this.api.get('/locations', {
      thread_id,
      from_date,
      to_date,
    })
  }

  getArticles(
    thread_id = 2,
    limit = 50,
    offset = 1,
    order_by = null,
    direccion_order_by = "asc",
  ) {
    return this.api.get('/v2/get_article_order_by', {
      thread_id,
      order_by,
      direccion_order_by,
      limit,
      offset,
    })
  }


  getArticlesById( id = 0 ) {
    return this.api.get('/v2/get_article_id', { article_id: id })
  }

  getArticleCrawler(linkData = {}) {
    this.generateApi()
    return this.api.get('/', {
      source_url: linkData.link, //link Base64
      main_tag: linkData.main_tag,
    })
  }

  getTimeline(thread_id, from_date = null, to_date = null, location_id = null) {
    return this.api.get('/timeline', {
      thread_id,
      from_date,
      to_date,
      location_id,
    })
  }

  getBookmark(
    type = null,
    user_id,
    article_id = null,
    from_date = null,
    to_date = null,
    limit = 32,
    page = 1,
    location_id = null
  ) {
    let url = '/bookmarks'
    let params = {}

    switch (type) {
      case 'check_bookmark':
        url = `${url}/${user_id}/articles/${article_id}`
        break
      case 'user_locations':
        params = { user_id, from_date, to_date }
        url = `${url}/${user_id}/locations`
        break
      case 'user_timeline':
        params = { user_id, from_date, to_date, location_id }
        url = `${url}/${user_id}/timeline`
        break
      default:
        params = {
          user_id,
          from_date,
          to_date,
          limit,
          page,
        }
        url = `${url}/${user_id}/articles`
    }
    return this.api.get(url, { ...params })
  }

  getWeatherDaily(location_id, to_date = null) {
    return this.api.get(`/weather/daily`, { location_id, to_date })
  }

  getWeatherHourly(location_id, to_date = null) {
    return this.api.get(`/weather/hourly`, { location_id, to_date })
  }

  getWeatherAssets() {
    return this.api.get(`/weather/assets`)
  }

  getWeatherLocations() {
    return this.api.get(`/weather/locations`)
  }
}
