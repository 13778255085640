import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import ReactDOM from 'react-dom'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 100000,
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
    '& button': {
      marginTop: '1.5rem',
      marginRight: '1rem',
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function ServerModal({
  id,
  isOpen,
  onClose = () => {},
  onAccept = () => {},
  title,
  description,
  children,
  showBtns,
}) {
  const classes = useStyles()
  const rootRef = React.useRef(null)

  const onClickAccept = () => {
    onAccept(id)
    onClose()
  }

  return isOpen
    ? ReactDOM.createPortal(
        <div className={classes.root} ref={rootRef}>
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={isOpen}
            onClose={onClose}
            aria-labelledby='server-modal-title'
            aria-describedby='server-modal-description'
            className={classes.modal}
            container={() => rootRef.current}
          >
            <div className={classes.paper}>
              {title && <h2 id='server-modal-title'>{title}</h2>}
              {description && (
                <p id='server-modal-description'>{description}</p>
              )}
              {children}
              {showBtns && (
                <>
                  <Button
                    size='large'
                    variant='outlined'
                    disableElevation
                    color='primary'
                    onClick={onClose}
                  >
                    Decline
                  </Button>
                  <Button
                    size='large'
                    variant='contained'
                    disableElevation
                    color='primary'
                    onClick={onClickAccept}
                  >
                    Accept
                  </Button>
                </>
              )}
            </div>
          </Modal>
        </div>,
        document.body
      )
    : null
}
