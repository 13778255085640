import API from './api.config'
import API_GET from './api.get'
import API_POST from './api.post'
import API_PUT from './api.put'
import API_DELETE from './api.delete'

export default function api() {
  return { API, API_PUT, API_POST, API_DELETE, API_GET }
}

export { API, API_PUT, API_POST, API_DELETE, API_GET }
