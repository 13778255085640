import reducer, { initialState } from './source.reducer'
import types from './source.types'

import * as sagas from './source.sagas'
import * as actions from './source.actions'
import * as utils from './source.utils'
import * as selectors from './source.selectors'

export const source = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
