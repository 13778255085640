import type from './token.types'
import * as utils from './token.utils'

export const initialState = {
  currentToken: null,
  list: {
    data: [],
    header: [],
    body: [],
    limit: 50,
    page: 1,
    total: 1,
  },
  errors: {
    current: {},
    data: [],
  },
  isLoading: false,
}

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.TOKEN_TRY:
      return {
        ...state,
        isLoading: true,
      }

    case type.SET_CURRENT_TOKEN:
      return {
        ...state,
        currentToken: action.payload,
        isLoading: false,
      }

    case type.LIST_SUCCESS: {
      const list = utils.formatData(action.payload)
      return {
        ...state,
          isLoading: false,
          errors: {current: {}, data: []},
          list
      }
    }


    case type.DELETE_TOKEN: {
      const id = Number(action.payload)
      const data = state.list.data.filter(token => token.id !== id)
      const body = state.list.body.filter(token => token.id !== id)
      if (data) {
        return {
          ...state,
          isLoading: false,
          list: {
            ...state.list,
            data,
            body,
          },
        }
      }
      return state
    }

    case type.TOKEN_FAILED:
      const errors = utils.addError(state.errors, action)
      return { ...state, errors: { ...errors }, isLoading: false }

    case type.CLEAR_CURRENT_ERROR_TOKEN: {
      return {
        ...state,
        errors: {
          ...state.errors,
          current: {},
        },
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default tokenReducer
