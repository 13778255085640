import API from './api.config'

/**
 * POST
 **/

export default class API_POST extends API {
  constructor(baseURL = process.env.URL_API) {
    super(baseURL)
  }

  addArticle(articleData = {}) {
    this.generateApi()
    return this.api.post('/v2/create_article', {
      main_tag: "default",
      article: {
        headline: articleData.headline,
        lead_paragraph: articleData.lead_paragraph,
        media_type: articleData.media_type,
        media_link: articleData.media_link,
        link: articleData.link,
        datetime: articleData.datetime,
        last_update_datetime: articleData.last_update_datetime,
        internal_tags: articleData.internal_tags,
        authors: articleData.authors,
        article_source_id: articleData.article_source_id,
        locations: articleData.locations,
        full_text: articleData.full_text,
        meta: articleData.meta,
        threads: articleData.threads,
        status: articleData.status,
      },
    })
  }

  addLocation(
    data = {
      city: null,
      country: null,
      longitude: null,
      latitude: null,
    }
  ) {
    this.generateApi()
    return this.api.post('/locations/create_location', data)
  }

  addBookmark(user_id, article_id) {
    this.generateApi()
    return this.api.post('/bookmark/toggle', { user_id, article_id })
  }

  addTwitter(data) {
    this.generateApi()
    return this.api.post('/twitter/upload', data)
  }

  addThread(data) {
    this.generateApi()
    return this.api.post('/create_thread', data)
  }
  /* addSource
* {
*    @param string logo *
     @param string name *
     @param string city *
     @param string state
     @param string country *
     @param string latitude *
     @param string longitude *
     @param string pattern *
* }

* }
* */

  addSource(
    data = {
      logo: null,
      name: null,
      city: null,
      state: '',
      country: null,
      latitude: null,
      longitude: null,
      pattern: null,
    }
  ) {
    this.generateApi()
    return this.api.post('/sources/create_source', {
      ...data,
    })
  }

  addCluster(cluster_id, article_id){
      this.generateApi()
      return this.api.post('/clusters/add_article?cluster_id=' + cluster_id + '&article_id=' + article_id)
  }

  deleteArticleCluster(cluster_id, article_id){
    this.generateApi()
    return this.api.post('/clusters/remove_article?cluster_id=' + cluster_id + '&article_id=' + article_id)
  }
}
