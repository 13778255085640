import API from './api.config'

/**
 * DELETE
 **/

export default class API_DELETE extends API {
  constructor(baseURL = process.env.URL_API) {
    super(baseURL)
  }

  deleteSource(id) {
    this.generateApi()
    return this.api.delete(`/sources/${id}`)
  }

  deleteCluster(id) {
     this.generateApi()
      return this.api.delete(`/clusters/${id}`)
  }

  deleteThread(id) {
    this.generateApi()
    return this.api.delete(`/thread/${id}`)
  }

  deleteLocation(id) {
    this.generateApi()
    return this.api.delete(`/locations/${id}`)
  }

  deleteArticle(id) {
    this.generateApi()
    return this.api.delete(`/delete/${id}`)
  }

  deleteUsers(user) {
    this.generateApi()
    return this.api.delete(`/users/delete`, {
        user_uid: user.user_uid,
        user_id: user.user_id
    })
  }

}
