import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  Button,
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import Layout from '../../components/layout/layout.component'
import Multiselect from '../../components/multiselect/multiselect.component'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../state/modules'
import { useParams, useNavigate } from 'react-router-dom'
import {defaultDataModal} from "../../components/modal/modal.utils";
import ServerModal from '../../components/modal/modal.component';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& button': {
      margin: theme.spacing(1),
    },
  },
  cover: {
    width: '100%',
    height: 400,
    marginLeft: '.5rem',
    marginTop: '2rem',
  },
}))

function UserPageDetail({
      users,
      setCurrentUserRequested,
      currentUser,
      updateUser,
      addUser,
      deleteUser,
      setCurrentUser
})
{
  const initialUser = {
      name: '',
      lastname: '',
      email: '',
      rol: 2,
      welcome: false,
      emailVerified: false,
      status: false,
      verified: false
  }
  const classes = useStyles()
  const [modal, setModal] = useState({ ...defaultDataModal })
  const [size, setSize] = useState()
  const [user, setUser] = useState(initialUser)
  const { id } = useParams()
  const navigate = useNavigate()
  const roles = [
      { id: 0, name: 'Admin'},
      { id: 1, name: 'Editor'},
      { id: 2, name: 'User'},
  ]

  /*  Admin user. Can do anything inside Threader: Create/Update/Delete new threads, articles, locations, sources, etc. This user is the only authorized to create new user of any type

    Editor user. This user is limited only to create/update/delete articles based on the current options like locations, sources, threads, etc. This user can't access other section rather than his profile and articles.

    Regular user. This user is for eyes only porpuses. He can browse through the articles in the several threads but nothing more except his own profile. */

  const handleSizeChange = size => {
    setSize(size)
  }

  useEffect(() => {
    const userFilter =
      id !== 'null' ? users.data.find(row => row.local_id === String(id)) : null
    if (userFilter) {
      userFilter.uid = userFilter.local_id
      setCurrentUserRequested(userFilter)
      setUser({
        id: userFilter?.id || null,
        userId: userFilter.local_id,
        name: userFilter.name,
        lastName: userFilter.lastname,
        email: userFilter.email,
        rol: userFilter.rol,
        welcome: userFilter.welcome ? true : false,
        emailVerified: userFilter.isEmailVerified ? true : false,
        status: userFilter.status ? true : false,
        verified: userFilter.verified ? true : false
      })
      return
    }

    setCurrentUser(initialUser)
    setUser(initialUser)
  }, [])

  useEffect(()=>{
    if (currentUser == null){
        setUser(initialUser)
        return
    }
    let userData = currentUser;
    setUser({
          id: userData?.id || null,
          userId: userData.local_id,
          name: userData.name,
          lastname: userData.lastname,
          email: userData.email,
          rol: userData.rol,
          welcome: userData.welcome ? true : false,
          emailVerified: userData.isEmailVerified ? true : false,
          status: userData.status ? true : false,
          verified: userData.verified ? true : false
    })
  },[currentUser])

  const handleSubmit = event => {
    event.preventDefault()
    const userData = {
      userId: user.userId ? user.userId : null,
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      status: user.status,
      verified: user.verified,
      isEmailVerified: user.emailVerified,
      welcome: user.welcome,
      rol: user.rol
    }

    if (id !== 'null' && user.id) {
        userData.id = currentUser.id
        updateUser(userData)
    }else {
        addUser(userData);
    }
    navigate(-1)
  }

  const handleClickBack = ({ pathname }) => {
    navigate(`/${pathname[0]}`)
  }

  const handleAccept = async () => {
      if (modal.type === 'delete') {
          deleteUser(id)
          navigate(-1)
      }
  }

  const handleCloseModal = () => {
      setModal({ ...defaultDataModal, open: false })
  }

  const onDeleteHandler = () => {
      setModal({
          open: true,
          title: 'Delete Current User',
          description: 'Are you sure you want to delete the current user? This can\'t be undone',
          type: 'delete',
          id: null,
      })
  }

  return (
    <Layout
      hideBreadcrum
      showBackBtn
      showDeleteBtn={id !== 'null' ? true : false}
      onClickBack={handleClickBack}
      onClickDelete={onDeleteHandler}
      onSizeChange={handleSizeChange}
    >
      <form
        className={classes.root}
        style={{ width: size === 'xl' ? '60%' : '100%' }}
        //noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <TextField
          required
          id='standard-basic'
          label='Name'
          //defaultValue={currentUser ? currentUser.item.name : '(Name)'}
          value={user.name}
          onInput={e => setUser({...user, name: e.target.value})}
        />
        <TextField
          required
          id='standard-basic-last-name'
          label='LastName'
          //defaultValue={currentUser ? currentUser.item.lastname : '(Last name)'}
          value={user.lastname}
          onInput={e => setUser({...user, lastname: e.target.value})}
        />
        <TextField
          required
          id='standard-basic'
          label='email'
          //defaultValue={currentUser ? currentUser.item.email : '(Email)'}
          value={user.email}
          onInput={e => setUser({...user, email: e.target.value})}
        />

        <Multiselect
            required
            name='Rol'
            dataSet={roles}
            multiple={false}
            defaultData={() => user?.rol >= 0  ? user.rol : 2 }
            setCurrentItems={id => {
                setUser({ ...user, rol: id })
            }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.status}
              onChange={() => setUser({...user, status: !user.status})}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              color='primary'
              name='checkedF'
            />
          }
          label='Status'
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.verified}
              onChange={() => setUser({...user, verified: !user.verified})}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              color='primary'
              name='checkedF'
            />
          }
          label='Verified'
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.emailVerified}
              onChange={() => setUser({...user, emailVerified: !user.emailVerified})}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              color='primary'
              name='checkedF'
            />
          }
          label='Email Verified'
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.welcome}
              onChange={() => setUser({...user, welcome: !user.welcome})}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              color='primary'
              name='checkedF'
            />
          }
          label='Welcomed'
        />

        <Box m={5} />
        <Grid container justify='flex-end'>
          <Button size='large' variant='outlined'>
            Cancel
          </Button>
          <Button
            size='large'
            variant='contained'
            disableElevation
            color='primary'
            type='submit'
          >
            Save
          </Button>
        </Grid>
      </form>
      <ServerModal
          isOpen={modal.open}
          title={modal.title}
          description={modal.description}
          id={modal.id}
          showBtns
          onAccept={handleAccept}
          onClose={handleCloseModal}
      />
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  users: selectors.user.selectUserList,
  currentUser: selectors.user.selectCurrentUser,
  loadingUsers: selectors.user.selectUserLoading,
})

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(actions.user.setCurrentUser(user)),
  setCurrentUserRequested: user => dispatch(actions.user.setCurrentUserRequested(user)),
  updateUser: user => dispatch(actions.user.updateUser(user)),
  addUser: user => dispatch(actions.user.addUser(user)),
  deleteUser: (userId) => dispatch(actions.user.deleteUser(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPageDetail)
