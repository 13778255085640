import React from 'react'
import { titleCase } from '../../../components/tags/tags.utils'
import type from './source.types'

export const addError = (errors, action) => {
  const errorId = errors.data.findIndex(
    error => error.name === action.payload.name
  )

  errors.current = action.payload

  if (errorId === -1) errors.data.push(action.payload)
  else errors.data[errorId] = action.payload

  return errors
}

export const updateSource = (list, errors, action) => {
  const updateSourceId = list.findIndex(
    source => source.id === action.payload.id
  )
  if (updateSourceId === -1)
    errors.push({
      name: type.UPDATE_SOURCE,
      error: `${updateSourceId} don't exist`,
    })
  else list[updateSourceId] = action.payload
  return { list, errors }
}

export const formatData = payload => {
  const data = payload.data.map(row => {
    return {
      id: row?.id,
      rowId: row?.id,
      col1: row?.name,
      col2: row?.logo,
      col3: titleCase(row?.city),
      col4: titleCase(row?.country),
    }
  })

  const header = [
    { field: 'id', hide: true },
    { field: 'rowId', headerName: 'id' },
    { field: 'col1', headerName: 'Name', width: 150 },
    {
      field: 'col2',
      headerName: 'Logo',
      width: 100,
      renderCell: params => {
        return <img src={params.row.col2} alt={params.row.col1} height={20} />
      },
    },
    { field: 'col3', headerName: 'City', width: 150 },
    { field: 'col4', headerName: 'Country', width: 250 },
  ]

  const body = [...data]

  return {
    data: [...payload.data],
    header,
    body,
    limit: 50,
    page: 1,
    total: 1,
  }
}
