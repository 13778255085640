import { all, call, put, takeLatest } from 'redux-saga/effects'
import type from './cluster.types'
import { API_GET, API_POST, API_PUT, API_DELETE } from '../../../services'

const apiGet = new API_GET()
const apiPost = new API_POST()
const apiPut = new API_PUT()
const apiDelete = new API_DELETE()

export function* tryClusterList() {
  try {
    yield put({ type: type.CLUSTER_TRY })
    const response = yield apiGet.getClusters()

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.SOURCE_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchClusterList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryClusterList)
}

function* tryCurrentCluster(action) {
  try {
    yield put({ type: type.CLUSTER_TRY })
    const id = action.payload
    const response = yield apiGet.getClusters(id)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.SET_CURRENT_CLUSTER, payload })
  } catch (error) {
    yield put({
      type: type.CLUSTER_FAILED,
      payload: { name: type.SET_CURRENT_CLUSTER, error: error.message },
    })
  }
}

export function* watchCurrentCluster() {
  yield takeLatest(type.SET_CURRENT_CLUSTER_REQUESTED, tryCurrentCluster)
}

export function* tryAddCluster(action) {
  try {
    yield put({ type: type.CLUSTER_TRY })

    const {cluster_id, article_id} = action.payload
    const response = yield apiPost.addCluster(cluster_id, article_id)

    if (!response.ok) throw new Error(response.problem)
    const payload = yield response.data

    yield put({
      type: type.ADD_CLUSTER,
      payload,
    })
  } catch (error) {
    yield put({
      type: type.CLUSTER_FAILED,
      payload: { name: type.ADD_CLUSTER, error: error.message },
    })
  }
}

export function* watchAddCluster() {
  yield takeLatest(type.ADD_CLUSTER_REQUESTED, tryAddCluster)
}

export function* tryDeleteCluster(action) {
  try {
    yield put({ type: type.CLUSTER_TRY })
    const id = action.payload
    const response = yield apiDelete.deleteCluster(id)

    if (!response.ok) throw new Error(response.problem)

    yield put({
      type: type.DELETE_CLUSTER,
      payload: id,
    })
  } catch (error) {
    yield put({
      type: type.CLUSTER_FAILED,
      payload: { name: type.DELETE_CLUSTER, error: error.message },
    })
  }
}

export function* watchDeleteCluster() {
  yield takeLatest(type.DELETE_CLUSTER, tryDeleteCluster)
}

export function* tryDeleteArticleCluster(action) {
  try {
    yield put({ type: type.CLUSTER_TRY })
    
    const {cluster_id, article_id} = action.payload
    const response = yield apiPost.deleteArticleCluster(cluster_id, article_id)

    if (!response.ok) throw new Error(response.problem)

    // yield put({
    //   type: type.DELETE_ARTICLE_CLUSTER_REQUESTED,
    //   payload: action.payload,
    // })
  } catch (error) {
    yield put({
      type: type.CLUSTER_FAILED,
      payload: { name: type.DELETE_ARTICLE_CLUSTER_REQUESTED, error: error.message },
    })
  }
}

export function* watchDeleteArticleCluster() {
  yield takeLatest(type.DELETE_ARTICLE_CLUSTER_REQUESTED, tryDeleteArticleCluster)
}

export function* tryUpdateCluster(action) {
  try {
    yield put({ type: type.CLUSTER_TRY })
    const { id, data } = action.payload
    const response = yield apiPut.updateCluster(id, data)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data
    yield put({
      type: type.UPDATE_CLUSTER,
      payload: { id, ...payload },
    })
  } catch (error) {
    yield put({
      type: type.CLUSTER_FAILED,
      payload: { name: type.UPDATE_CLUSTER, error: error.message },
    })
  }
}

export function* watchUpdateCluster() {
  yield takeLatest(type.UPDATE_CLUSTER_REQUESTED, tryUpdateCluster)
}

export function* clusterSagas() {
  yield all([
    call(tryClusterList),
    watchClusterList(),
    watchAddCluster(),
    watchCurrentCluster(),
    watchDeleteCluster(),
    watchDeleteArticleCluster(),
    watchUpdateCluster(),
  ])
}
