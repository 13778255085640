import { createSelector } from 'reselect'
import { NAME } from './source.types'

export const selectSource = state => state[NAME]

export const selectSourceLoading = createSelector(
  [selectSource],
  source => source.isLoading
)

export const selectCurrentSource = createSelector(
  [selectSource],
  source => source.currentSource
)

export const selectSourceList = createSelector(
  [selectSource],
  source => source.list
)

export const selectSourceErrors = createSelector(
  [selectSource],
  source => source.errors
)

export const selectSourceCurrentError = createSelector(
  [selectSourceErrors],
  error => error.current
)
