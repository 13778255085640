import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import TableGrid from '../../components/table.grid/table.grid.component'
import {actions, selectors, user} from '../../state/modules'
import { useNavigate } from 'react-router-dom'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'

function Source({
  sources,
  setCurrentSource,
  sourcesLoading,
  deleteSource,
  currentSourceError,
  clearCurrentSourceError,
  currentUserAuth
}) {
  const pageName = 'Source'
  const navigate = useNavigate()
  const [modal, setModal] = useState({ ...defaultDataModal })
  const rolUser = currentUserAuth?.rol > -1 ? currentUserAuth.rol : 3

  useEffect(() => {
      if (rolUser != 0) {
          return navigate(`/`)
      }
  },[])

  const handleClickAddNew = () => {
    navigate(`/${pageName.toLowerCase()}/detail/null`)
  }
  const onEditHandler = row => {
    const currentSource = sources.data.find(source => source.id === row.id)
    if (currentSource) {
      setCurrentSource(currentSource)
      return navigate(`/${pageName.toLowerCase()}/detail/${row.rowId}`)
    }
    return alert(`${row.id} don't exist`)
  }

  const onDeleteHandler = row => {
    setModal({
      open: true,
      title: 'Notice!',
      description: `"${row.col1} #${row.rowId} " will be delete`,
      type: 'delete',
      id: row.rowId,
    })
  }

  const handleCloseModal = () => {
    setModal({ ...defaultDataModal, open: false })
  }

  const handleAccept = id => {
    if (modal.type === 'delete') {
      deleteSource(id)
    } else if (modal.type === 'error') {
      setModal({ ...defaultDataModal, open: false })
      clearCurrentSourceError()
    }
  }

  useEffect(() => {
    if (currentSourceError.name) {
      setModal({
        open: true,
        title: 'Error!',
        description: `${currentSourceError.error}`,
        type: 'error',
        id: null,
      })
    }
  }, [currentSourceError.name])

  return (
    <Layout>
      <TableGrid
        name={pageName}
        rows={sources.body}
        columns={sources.header}
        loading={sourcesLoading}
        limit={25}
        onEdit={onEditHandler}
        onDelete={onDeleteHandler}
        onClickAddNew={handleClickAddNew}
        rolUser={rolUser}
      />
      <ServerModal
        isOpen={modal.open}
        title={modal.title}
        description={modal.description}
        id={modal.id}
        showBtns
        onAccept={handleAccept}
        onClose={handleCloseModal}
      />
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  sources: selectors.source.selectSourceList,
  sourcesLoading: selectors.source.selectSourceLoading,
  currentSourceError: selectors.source.selectSourceCurrentError,
  currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
  setCurrentSource: source => dispatch(actions.source.setCurrentSource(source)),
  deleteSource: id => dispatch(actions.source.deleteSource(id)),
  clearCurrentSourceError: () => dispatch(actions.source.clearCurrentError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Source)
