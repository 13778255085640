import { all, call, put, takeLatest } from 'redux-saga/effects'
import type from './location.types'
import { API_GET, API_POST, API_PUT, API_DELETE } from '../../../services'

const apiGet = new API_GET()
const apiPost = new API_POST()
const apiPut = new API_PUT()
const apiDelete = new API_DELETE()

const apiGetParser = new API_GET(process.env.URL_API_PARSER)

export function* tryLocationsList() {
  try {
    yield put({ type: type.LOCATION_TRY })

    const response = yield apiGet.getLocations()

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.LOCATION_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchLocationList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryLocationsList)
}

function* tryCurrentLocation(action) {
  try {
    yield put({ type: type.LOCATION_TRY })

    const { url, thread } = action.payload
    const response = yield apiGetParser.getLocation(url, thread)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.SET_CURRENT_LOCATION, payload })
  } catch (error) {
    yield put({
      type: type.LOCATION_FAILED,
      payload: { name: type.SET_CURRENT_LOCATION, error: error.message },
    })
  }
}

export function* watchCurrentLocation() {
  yield takeLatest(type.SET_CURRENT_LOCATION_REQUESTED, tryCurrentLocation)
}

export function* addLocation(action) {
  try {
    yield put({ type: type.LOCATION_TRY })

    const response = yield apiPost.addLocation(action.payload)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({
      type: type.ADD_LOCATION,
      payload,
    })
  } catch (error) {
    yield put({
      type: type.LOCATION_FAILED,
      payload: { name: type.ADD_LOCATION, error: error.message },
    })
  }
}

export function* watchAddLocation() {
  yield takeLatest(type.ADD_LOCATION_REQUESTED, addLocation)
}

export function* deleteLocation(action) {
  try {
    yield put({ type: type.LOCATION_TRY })

    const id = action.payload
    const response = yield apiDelete.deleteLocation(id)

    if (!response.ok) throw new Error(response.problem)

    yield put({
      type: type.DELETE_LOCATION,
      payload: id,
    })
  } catch (error) {
    yield put({
      type: type.LOCATION_FAILED,
      payload: { name: type.DELETE_LOCATION, error: error.message },
    })
  }
}

export function* watchDeleteLocation() {
  yield takeLatest(type.DELETE_LOCATION_REQUESTED, deleteLocation)
}

export function* updateLocation(action) {
  try {
    yield put({ type: type.LOCATION_TRY })

    const { id, data } = action.payload

    const response = yield apiPut.updateLocation(id, data)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({
      type: type.UPDATE_LOCATION,
      payload: { id, ...payload },
    })
  } catch (error) {
    yield put({
      type: type.LOCATION_FAILED,
      payload: { name: type.UPDATE_LOCATION, error: error.message },
    })
  }
}

export function* watchUpdateLocation() {
  yield takeLatest(type.UPDATE_LOCATION_REQUESTED, updateLocation)
}

export function* locationSagas() {
  yield all([
    call(tryLocationsList),
    watchLocationList(),
    watchCurrentLocation(),
    watchAddLocation(),
    watchDeleteLocation(),
    watchUpdateLocation(),
  ])
}
