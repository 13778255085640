import { Grid, Box, Typography } from '@material-ui/core'
import React from 'react'
import Layout from '../../components/layout/layout.component'

export default function DashboardPage() {
  return (
    <Layout>
      <Grid item md={12}>
        <Grid item md={4}>
          <Typography variant='h2' color='primary'>
            Welcome to Threader CMS
          </Typography>
          <Box m={2} />
          <Typography variant='body1'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore
            ratione perferendis quae ab officia magni odio praesentium nesciunt
            eveniet, dolorem sed, soluta quos dolore cupiditate excepturi.
            Accusamus magni quas nesciunt?
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}
