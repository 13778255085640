import React, { useRef, useState } from 'react'
//import Badge from '@material-ui/core/Badge'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
//import MailIcon from '@material-ui/icons/Mail'
//import NotificationsIcon from '@material-ui/icons/Notifications'
import { logOut } from '../../firebase/firebase.utils'
import MoreIcon from '@material-ui/icons/MoreVert'
import { useStyles, StyledMenu } from './navbar.styles'
import {
  Hidden,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  InputBase,
  Divider,
} from '@material-ui/core'

import Slide from '@material-ui/core/Slide'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import LeftMenu from '../menu/menu.component'
import logo from './threader-logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {actions, user} from '../../state/modules'
import {createStructuredSelector} from "reselect/es/index";

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}


const Navbar = ( {props,setUserAuth, currentUserAuth}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorLeftMenu, setAnchorLeftMenu] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const signOutRef = useRef()
  const navigate = useNavigate()

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleLeftMenu = event => {
    setAnchorLeftMenu(event.currentTarget)
  }

  const handleCloseLeftMenu = () => {
    setAnchorLeftMenu(null)
  }

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleLink = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
    navigate('/profile')
  }

  const handleMenuClose = e => {
    setAnchorEl(null)
    handleMobileMenuClose()
    const signOutBtn = e.currentTarget?.firstChild?.data
    if (signOutBtn === signOutRef.current.firstChild.data) {
      setUserAuth(null)
      localStorage.clear()
      logOut();
      navigate('/')
    }
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLink}>Profile</MenuItem>
      {/*<MenuItem onClick={handleMenuClose}>My account</MenuItem>*/}
      <Divider />
      <MenuItem onClick={handleMenuClose} ref={signOutRef}>
        Sign out
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {
        //<MenuItem>
        //<IconButton aria-label='show 4 new mails' color='primary'>
        //<Badge badgeContent={4} color='secondary'>
        //<MailIcon />
        //</Badge>
        //</IconButton>
        //<p>Messages</p>
        //</MenuItem>
        //<MenuItem>
        //<IconButton aria-label='show 11 new notifications' color='primary'>
        //<Badge badgeContent={11} color='secondary'>
        //<NotificationsIcon />
        //</Badge>
        //</IconButton>
        //<p>Notifications</p>
        //</MenuItem>
      }
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='primary'
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.grow}>
      <HideOnScroll {...props}>
        <AppBar color='secondary'>
          <Toolbar>
            <Hidden lgUp>
              <IconButton
                edge='start'
                className={classes.menuButton}
                color='inherit'
                aria-label='open drawer'
                onClick={handleLeftMenu}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <StyledMenu
              id='customized-menu'
              anchorEl={anchorLeftMenu}
              keepMounted
              open={Boolean(anchorLeftMenu)}
              onClose={handleCloseLeftMenu}
            >
              <LeftMenu rol={currentUserAuth.rol} />
            </StyledMenu>

            <Link to='/dashboard'>
              <img src={logo} width='100' />
            </Link>

            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder='Search…'
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {
                //<IconButton aria-label='show 4 new mails' color='inherit'>
                //<Badge badgeContent={4} color='primary'>
                //<MailIcon />
                //</Badge>
                //</IconButton>
                //<IconButton
                //aria-label='show 17 new notifications'
                //color='inherit'
                //>
                //<Badge badgeContent={17} color='primary'>
                //<NotificationsIcon />
                //</Badge>
                //</IconButton>
              }
              <IconButton
                edge='end'
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                color='primary'
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <div className={classes.offset} />
      {renderMobileMenu}
      {renderMenu}
    </div>
  )
}


const mapStateToProps = createStructuredSelector({
    currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
    setUserAuth: user => dispatch(actions.user.setCurrentUserAuth(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
