import type from './timeline.types'
import * as utils from './timeline.utils'

export const initialState = {
  currentTimeline: null,
  list: [],
  errors: [],
  isLoading: null,
}

const timelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.TIMELINE_TRY:
      return { ...state, isLoading: true }

    case type.SET_CURRENT_TIMELINE:
      return {
        ...state,
        currentTimeline: action.payload,
        isLoading: false,
      }

    case type.LIST_SUCCESS:
      return { ...state, isLoading: false, list: action.payload }

    case type.ADD_TIMELINE:
      return {
        ...state,
        isLoading: false,
        list: [action.payload, ...state.list],
      }

    case type.UPDATE_TIMELINE:
      const updatedTimeline = utils.updateTimeline(
        state.list,
        state.errors,
        action
      )
      return { ...state, ...updatedTimeline, isLoading: false }

    case type.DELETE_TIMELINE:
      const newTimelineList = state.list.filter(
        timeline => timeline.id !== action.payload
      )
      return {
        ...state,
        isLoading: false,
        list: newTimelineList,
      }

    case type.TIMELINE_FAILED:
      const errors = utils.addError(state.errors, action)
      return { ...state, errors: { ...errors }, isLoading: false }

    case type.CLEAR_CURRENT_ERROR_TIMELINE: {
      return {
        ...state,
        errors: {
          ...state.errors,
          current: {},
        },
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default timelineReducer
