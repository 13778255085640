import API from './api.config'

/**
 * PUT
 **/

export default class API_PUT extends API {
  constructor(baseURL = process.env.URL_API) {
    super(baseURL)
  }

  updateSource(
    id,
    data = {
      logo: null,
      name: null,
      city: null,
      state: null,
      country: null,
      latitude: null,
      longitude: null,
      pattern: null,
    }
  ) {
    this.generateApi()
    return this.api.put(`/sources/${id}`, { ...data })
  }

  updateThread(id, body) {
    this.generateApi()
    return this.api.put(`/thread/${id}`, body)
  }

  updateLocation(
    id,
    body = {
      city: null,
      country: null,
      longitude: null,
      latitude: null,
    }
  ) {
    this.generateApi()
    return this.api.put(`/locations/${id}`, body)
  }
}
