import React from 'react'
import { useRoutes, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from './state/modules'

// Pages
import DashboardPage from './pages/dashboard/dashboard.page'

import ThreadPage from './pages/thread/thread.list.page'
import ThreadPageDetail from './pages/thread/thread.detail.page'

import PublicationPage from './pages/publication/publication.page'
import PublicationPageDetail from './pages/publication/publication.detail.page'

import ArticlePage from './pages/article/article.list.page'
import ArticlePageDetail from './pages/article/article.detail.page'

import ClusterPage from './pages/cluster/cluster.list.page'
import ClusterPageDetail from './pages/cluster/cluster.detail.page'

import SourcePage from './pages/source/source.list.page'
import SourcePageDetail from './pages/source/source.detail.page'

import LocationPage from './pages/location/location.list.page'
import LocationPageDetail from './pages/location/location.detail.page'

import UserPage from './pages/user/user.list.page'
import UserPageDetail from './pages/user/user.detail.page'
import UserProfielDetail from './pages/user/user.profile.page'

import LoginPage from './pages/login/login.page'
import ResetPasswordPage from './pages/login/reset.password.page'

import TokenDevices from './pages/token/token.list.page'

//redux
import { user } from '../src/state/modules'

import './App.css'

const App = ({ currentUserAuth, setUserAuth }) => {
  
  if (currentUserAuth == null)
       return <LoginPage />;

  let expirationTime = currentUserAuth.multiFactor.user.stsTokenManager.expirationTime;
  var nowTimeStamp = new Date().getTime();
  if (nowTimeStamp >= expirationTime ){
    setUserAuth(null);
    localStorage.removeItem('user')
    return <LoginPage />;
  }

  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to='/dashboard' />,
    },
    {
      path: '/resetpassword',
      element: <ResetPasswordPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardPage />,
    },
    {
      path: '/article',
      element: <ArticlePage />,
    },
    {
      path: '/article/detail/:id',
      element: <ArticlePageDetail />,
    },
    {
      path: '/thread',
      element: <ThreadPage />,
    },
    {
      path: '/thread/detail/:id',
      element: <ThreadPageDetail />,
    },
    {
      path: '/source',
      element: <SourcePage />,
    },
    {
      path: '/source/detail/:id',
      element: <SourcePageDetail />,
    },
    {
      path: '/cluster',
      element: <ClusterPage />,
    },
    {
      path: '/cluster/detail/:id',
      element: <ClusterPageDetail />,
    },
    {
      path: '/location',
      element: <LocationPage />,
    },
    {
      path: '/location/detail/:id',
      element: <LocationPageDetail />,
    },
    {
      path: '/publication',
      element: <PublicationPage />,
    },
    {
      path: '/publication/detail/:id',
      element: <PublicationPageDetail />,
    },
    {
      path: '/users',
      element: <UserPage />,
    },
    {
      path: '/users/detail/:id',
      element: <UserPageDetail />,
    },
    {
      path: '/profile',
      element: <UserProfielDetail />,
    },
    {
      path: '/tokens',
      element: <TokenDevices />,
    },
  ])
  return routes
}

//function App({ checkUserSession, currentUser }) {
//useEffect(() => {
////checkUserSession(1)
//}, [])

//return (
//<Switch>
//<Route
//exact
//path='/'
//render={() =>
//currentUser ? <Redirect to='/dashboard' /> : <LoginPage />
//}
///>

//<Route path='/resetpassword' component={ResetPasswordPage} />

//<Route path='/dashboard' component={DashboardPage} />

//<Route exact path='/article' component={ArticlePage} />
//<Route path='/article/detail/:id' component={ArticlePageDetail} />

//<Route exact path='/thread' component={ThreadPage} />
//<Route path='/thread/detail/:id' component={ThreadPageDetail} />

//<Route exact path='/publication' component={PublicationPage} />
//<Route path='/publication/detail/:id' component={PublicationPageDetail} />

//<Route exact path='/users' component={UserPage} />
//<Route path='/users/detail/:id' component={UserPageDetail} />
//<Route path='/profile' component={UserProfielDetail} />
//</Switch>
//)
//}

const mapStateToProps = createStructuredSelector({
  currentUser: user.selectors.selectCurrentUser,
  currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
  checkUserSession: id => dispatch(user.actions.setCurrentUser({ id })),
  setUserAuth: user => dispatch(actions.user.setCurrentUserAuth(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
