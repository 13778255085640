import { all, call, put, takeLatest } from 'redux-saga/effects'
import type from './thread.types'
import { API_GET, API_POST, API_PUT, API_DELETE } from '../../../services'

const apiGet = new API_GET()
const apiPost = new API_POST()
const apiPut = new API_PUT()
const apiDelete = new API_DELETE()

export function* tryThreadsList() {
  try {
    yield put({ type: type.THREAD_TRY })

    const response = yield apiGet.getThreads()
    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.THREAD_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchThreadList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryThreadsList)
}

function* tryCurrentThread(action) {
  try {
    yield put({ type: type.THREAD_TRY })

    const payload = yield action.payload

    if (!payload && payload !== null) throw new Error('No data')

    yield put({ type: type.SET_CURRENT_THREAD, payload })
  } catch (error) {
    yield put({
      type: type.THREAD_FAILED,
      payload: { name: type.SET_CURRENT_THREAD, error: error.message },
    })
  }
}

export function* watchCurrentThread() {
  yield takeLatest(type.SET_CURRENT_THREAD_REQUESTED, tryCurrentThread)
}

export function* addThread(action) {
  try {
    const data = action.payload
    data.keywords = data.keywords.length > 0 ? JSON.stringify(data.keywords) : ''
    const response = yield apiPost.addThread(data)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({
      type: type.ADD_THREAD,
      payload,
    })
  } catch (error) {
    yield put({
      type: type.THREAD_FAILED,
      payload: { name: type.ADD_THREAD, error: error.message },
    })
  }
}

export function* watchAddThread() {
  yield takeLatest(type.ADD_THREAD_REQUESTED, addThread)
}

export function* deleteThread(action) {
  yield put({ type: type.THREAD_TRY })
  try {
    const response = yield apiDelete.deleteThread(action.payload)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({
      type: type.DELETE_THREAD,
      payload: { ...payload, id: action.payload },
    })
  } catch (error) {
    yield put({
      type: type.THREAD_FAILED,
      payload: { name: type.DELETE_THREAD, error: error.message },
    })
  }
}

export function* watchDeleteThread() {
  yield takeLatest(type.DELETE_THREAD_REQUESTED, deleteThread)
}

export function* updateThread(action) {
  yield put({ type: type.THREAD_TRY })
  try {
    const { id, data } = action.payload
    data.keywords = data.keywords.length > 0 ? JSON.stringify(data.keywords) : ''
    const response = yield apiPut.updateThread(id, data)

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({
      type: type.UPDATE_THREAD,
      payload: { id, ...payload },
    })
  } catch (error) {
    yield put({
      type: type.THREAD_FAILED,
      payload: { name: type.UPDATE_THREAD, error: error.message },
    })
  }
}

export function* watchUpdateThread() {
  yield takeLatest(type.UPDATE_THREAD_REQUESTED, updateThread)
}

export function* threadSagas() {
  yield all([
    call(tryThreadsList),
    //call(tryCurrentThread),
    watchThreadList(),
    watchCurrentThread(),
    watchAddThread(),
    watchDeleteThread(),
    watchUpdateThread(),
  ])
}
