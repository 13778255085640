import type from './cluster.types'
import * as utils from './cluster.utils'

export const initialState = {
  currentCluster: null,
  list: {
    data: [],
    header: [],
    body: [],
    limit: 50,
    page: 1,
    total: 1,
  },
  errors: {
    current: {},
    data: [],
  },
  isLoading: false,
}

const clusterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.CLUSTER_TRY:
      return {
        ...state,
        isLoading: true,
      }

    case type.SET_CURRENT_CLUSTER:
      return {
        ...state,
        currentCluster: action.payload,
        isLoading: false,
      }

    case type.LIST_SUCCESS: {
      const list = utils.formatData(action.payload)
      return { ...state, isLoading: false, list }
    }

    case type.ADD_CLUSTER: {
      const data = [...state.list.data, action.payload.data]
      const list = utils.formatData({ data })

      return {
        ...state,
        isLoading: false,
        list,
      }
    }

    case type.UPDATE_CLUSTER: {
      const index = state.list.data.findIndex(
        source => source.id === Number(action.payload.id)
      )

      if (index !== -1) {
        state.list.data[index] = {
          ...action.payload.data,
          id: Number(action.payload.id),
        }

        const list = utils.formatData({ data: state.list.data })
        return { ...state, list, isLoading: false }
      }

      return state
    }

    case type.DELETE_CLUSTER: {
      const id = Number(action.payload)
      const data = state.list.data.filter(cluster => cluster.id !== id)
      const body = state.list.body.filter(cluster => cluster.id !== id)
      if (data) {
        return {
          ...state,
          isLoading: false,
          list: {
            ...state.list,
            data,
            body,
          },
        }
      }
      return state
    }

    case type.CLUSTER_FAILED:
      const errors = utils.addError(state.errors, action)
      return { ...state, errors: { ...errors }, isLoading: false }

    case type.CLEAR_CURRENT_ERROR_CLUSTER: {
      return {
        ...state,
        errors: {
          ...state.errors,
          current: {},
        },
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default clusterReducer
