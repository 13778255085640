import reducer, { initialState } from './weather.reducer'
import types from './weather.types'

import * as sagas from './weather.sagas'
import * as actions from './weather.actions'
import * as utils from './weather.utils'
import * as selectors from './weather.selectors'

export const weather = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
