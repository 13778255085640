export const NAME = 'source' // same as the  global state

const types = {
  SOURCE_TRY: `[${NAME}]/SOURCE_TRY`,

  SET_CURRENT_SOURCE: `[${NAME}]/SET_CURRENT_SOURCE`,
  SET_CURRENT_SOURCE_REQUESTED: `[${NAME}]/SET_CURRENT_SOURCE_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_SOURCE: `[${NAME}]/ADD_SOURCE`,
  ADD_SOURCE_REQUESTED: `[${NAME}]/ADD_SOURCE_REQUESTED`,

  UPDATE_SOURCE: `[${NAME}]/UPDATE_SOURCE`,
  UPDATE_SOURCE_REQUESTED: `[${NAME}]/UPDATE_SOURCE_REQUESTED`,

  DELETE_SOURCE: `[${NAME}]/DELETE_SOURCE`,
  DELETE_SOURCE_REQUESTED: `[${NAME}]/DELETE_SOURCE_REQUESTED`,

  SOURCE_FAILED: `[${NAME}]/SOURCE_FAILED`,
  CLEAR_CURRENT_ERROR_SOURCE: `[${NAME}]/CLEAR_CURRENT_ERROR_SOURCE`,
}

export default types
