export const validURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}
export function base64EncodeUnicode(str) {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  let utf8Bytes = encodeURIComponent(str).replace(
    /^(?:[A-Za-z0-9]{4})*(?:[A-Za-z0-9]{2}==|[A-Za-z0-9]{3}=)?$/g,
    function (match, p1) {
      return String.fromCharCode('0x' + p1)
    }
  )

  return btoa(utf8Bytes)
}

export const getParam = name => {
  const params = new URLSearchParams(window.location.search)
  const paramThread = params.get(name)
  if (paramThread) {
    try {
      return JSON.parse(atob(paramThread))
    } catch (error) {
      return error
    }
  }
  return null
}

export const setObjParam = obj => {
  if (typeof obj !== 'object') return btoa(JSON.stringify(obj))
  return btoa(JSON.stringify(obj.id))
}

export function isImageURL(url) {
  return url.match(/\.(jpeg|jpg|gif|png|JPG)$/) || url.match(/http/g) !== null
}
