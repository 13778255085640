import React, { useState } from 'react'
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'

import { useStyles } from './multiselect.utils'

export default function Multiselect({
  name = 'Input Field',
  dataSet = [],
  defaultData = [],
  setCurrentItems = () => {},
  multiple = false,
  required = false,
  disabled = false,
}) {
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  const classes = useStyles()
  const [data, setData] = useState(defaultData)
  const handleChange = event => {
    setData(event.target.value)
    setCurrentItems(event.target.value)
  }

  const currentClassName = name?.replace(/\s/g, '-')?.toLowerCase()

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  return (
    <FormControl className={classes.formControl} required>
      <InputLabel id={`mutiple-chip-${currentClassName}`}>
        {name}
      </InputLabel>
      <Select
        labelId={`mutiple-chip-${currentClassName}`}
        id={`${currentClassName}-chip`}
        multiple={multiple}
        value={data}
        onChange={handleChange}
        input={<Input id={`select-${currentClassName}`} />}
        MenuProps={MenuProps}
        required={required}
        disabled={disabled}
      >
        {dataSet.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
