import reducer, { initialState } from './cluster.reducer'
import types from './cluster.types'

import * as sagas from './cluster.sagas'
import * as actions from './cluster.actions'
import * as utils from './cluster.utils'
import * as selectors from './cluster.selectors'

export const cluster = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
