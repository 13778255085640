export const NAME = 'location' // same as the  global state

const types = {
  LOCATION_TRY: `[${NAME}]/LOCATION_TRY`,

  SET_CURRENT_LOCATION: `[${NAME}]/SET_CURRENT_LOCATION`,
  SET_CURRENT_LOCATION_REQUESTED: `[${NAME}]/SET_CURRENT_LOCATION_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_LOCATION: `[${NAME}]/ADD_LOCATION`,
  ADD_LOCATION_REQUESTED: `[${NAME}]/ADD_LOCATION_REQUESTED`,

  UPDATE_LOCATION: `[${NAME}]/UPDATE_LOCATION`,
  UPDATE_LOCATION_REQUESTED: `[${NAME}]/UPDATE_LOCATION_REQUESTED`,

  DELETE_LOCATION: `[${NAME}]/DELETE_LOCATION`,
  DELETE_LOCATION_REQUESTED: `[${NAME}]/DELETE_LOCATION_REQUESTED`,

  LOCATION_FAILED: `[${NAME}]/LOCATION_FAILED`,
  CLEAR_CURRENT_ERROR_LOCATION: `[${NAME}]/CLEAR_CURRENT_ERROR_LOCATION`,
}

export default types
