import { all, put, takeLatest } from 'redux-saga/effects'
import type from './user.types'
import Service from '../../../services'
import {
  addUserDocument,
  updateProfileUser,
  getUserDocument,
  updateUserDocument
} from '../../../firebase/firebase.utils'

const Ser = Service()
const apiGet = new Ser.API_GET()
const apiDelete = new Ser.API_DELETE()


export function* tryUsersList() {
  try {
    yield put({ type: type.USER_TRY })

    const payload = yield apiGet.getUsers();

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.USER_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchUserList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryUsersList)
}

function* tryCurrentUser(action) {
  try {
    yield put({ type: type.USER_TRY })

    const response = yield getUserDocument(action.payload)

   // if (!response.ok) throw new Error(response.problem)

    const payload = yield response

    yield put({ type: type.SET_CURRENT_USER, payload })
  } catch (error) {
    yield put({
      type: type.USER_FAILED,
      payload: { name: type.SET_CURRENT_USER, error: error.message },
    })
  }
}

export function* watchCurrentUser() {
  yield takeLatest(type.SET_CURRENT_USER_REQUESTED, tryCurrentUser)
}

export function* addUser(action) {
  try {
    const response = yield addUserDocument(action.payload)

    const newUser = yield response.json()

    yield put({
      type: type.ADD_USER,
      payload: newUser,
    })
  } catch (error) {
    yield put({
      type: type.USER_FAILED,
      payload: { name: type.ADD_USER, error: error.message },
    })
  }
}

export function* watchAddUser() {
  yield takeLatest(type.ADD_USER_REQUESTED, addUser)
}

export function* deleteUser(action) {
  yield put({ type: type.USER_TRY })
  try {

    yield apiDelete.deleteUsers(action.payload);

    const deletedUser = yield action.payload.user_uid;

    yield put({
      type: type.DELETE_USER,
      payload: deletedUser,
    })
  } catch (error) {
    yield put({
      type: type.USER_FAILED,
      payload: { name: type.DELETE_USER, error: error.message },
    })
  }
}

export function* watchDeleteUser() {
  yield takeLatest(type.DELETE_USER_REQUESTED, deleteUser)
}

export function* updateUser(action) {
  try {
    //const response = yield updateUserDocument(action.payload)

    const response = yield updateUserDocument(action.payload);

    const updatedUser = yield action.payload.user_uid;

    yield put({
      type: type.UPDATE_USER,
      payload: updatedUser,

    })
  } catch (error) {
    yield put({
      type: type.USER_FAILED,
      payload: { name: type.UPDATE_USER, error: error.message },
    })
  }
}

export function* watchUpdateUser() {
  yield takeLatest(type.UPDATE_USER_REQUESTED, updateUser)
}


function* updateUserProfile(user) {
  yield updateProfileUser(user.payload)
}

export function* watchUpdateProfileUser() {
  yield takeLatest(type.UPDATE_USER_PROFILE_REQUESTED, updateUserProfile)
}

export function* userSagas() {
  yield all([
    //call(tryUsersList),
    //call(tryCurrentUser),
    watchUserList(),
    watchCurrentUser(),
    watchAddUser(),
    watchDeleteUser(),
    watchUpdateUser(),
    watchUpdateProfileUser(),
  ])
}
