import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { titleCase, exampleData } from './tags.utils'
/*tag*/

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: '-8px!important',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    '& .MuiTextField-root': {
      margin: '0!important',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 25px)',
      right: '-8px',
    },
  },
}))

export default function Tags({
  label = 'Tags',
  placeholder = 'Favorites',
  limitTags = 4,
  options = exampleData,
  defaultValue = [exampleData[0]],
  fieldToDisplay = 'name',
  required,
  onChange = () => {},
  disabled=false
}) {
  const classes = useStyles()

  //console.log('options', options)
  //console.log('defaultValue', defaultValue)

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        limitTags={limitTags}
        id='multiple-limit-tags'
        options={options}
        onChange={(e, newValue) => onChange([...newValue])}
        getOptionLabel={option => titleCase(option[fieldToDisplay])}
        getOptionSelected={(option, value) => option.id === value.id}
        defaultValue={defaultValue}
        value={defaultValue}
        renderInput={params => (
          <TextField
            {...params}
            variant='standard'
            label={`${label}`}
            placeholder={placeholder}
            required={
              required && (options.length === 0 || defaultValue.length === 0)
            }
          />
        )}
        disabled={disabled}
      />
    </div>
  )
}
