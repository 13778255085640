import type from './bookmark.types'

export const setCurrentBookmark = bookmark => ({
  type: type.SET_CURRENT_BOOKMARK_REQUESTED,
  payload: bookmark,
})

export const setListBookmark = bookmarks => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: bookmarks,
})

export const addBookmark = bookmark => ({
  type: type.ADD_BOOKMARK_REQUESTED,
  payload: bookmark,
})

export const updateBookmark = bookmark => ({
  type: type.UPDATE_BOOKMARK_REQUESTED,
  payload: bookmark,
})

export const deleteBookmark = bookmark_id => ({
  type: type.DELETE_BOOKMARK_REQUESTED,
  payload: bookmark_id,
})

export const bookmarkFailed = error => ({
  type: type.BOOKMARK_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_BOOKMARK,
})
