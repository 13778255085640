import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import TableGrid from '../../components/table.grid/table.grid.component'
import {actions, selectors, user} from '../../state/modules'
import { useNavigate } from 'react-router-dom'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'

function TokenDevices({
                tokens,
                tokenLoading,
                currentTokenError,
                clearCurrentTokenError,
                currentUserAuth
                }) {
    const pageName = 'Tokens Devices'
    const navigate = useNavigate()
    const [modal, setModal] = useState({ ...defaultDataModal })
    const rolUser = currentUserAuth?.rol > -1 ? currentUserAuth.rol : 3

    useEffect(() => {
        if (rolUser != 0) {
            return navigate(`/`)
        }
    },[])

    const handleClickAddNew = () => {
        navigate(`/${pageName.toLowerCase()}/detail/null`)
    }

    const onDeleteHandler = row => {
        setModal({
            open: true,
            title: 'Notice!',
            description: `"${row.col1} #${row.rowId} " will be delete`,
            type: 'delete',
            id: row.rowId,
        })
    }

    const handleCloseModal = () => {
        setModal({ ...defaultDataModal, open: false })
    }

    const handleAccept = () => {
        if (modal.type === 'delete') {
            //deleteToken(id)
        } else if (modal.type === 'error') {
            setModal({ ...defaultDataModal, open: false })
            clearCurrentTokenError()
        }
    }

    useEffect(() => {
        if (currentTokenError.name) {
            setModal({
                open: true,
                title: 'Error!',
                description: `${currentTokenError.error}`,
                type: 'error',
                id: null,
            })
        }
    }, [currentTokenError.name])

    return (
        <Layout>
            <TableGrid
                name={pageName}
                rows={tokens.body}
                columns={tokens.header}
                loading={tokenLoading}
                limit={25}
                showAddBtn={false}
                showEditBtn={false}
                onDelete={onDeleteHandler}
                onClickAddNew={handleClickAddNew}
                rolUser={rolUser}
            />
            <ServerModal
                isOpen={modal.open}
                title={modal.title}
                description={modal.description}
                id={modal.id}
                showBtns
                onAccept={handleAccept}
                onClose={handleCloseModal}
            />
        </Layout>
    )
}

const mapStateToProps = createStructuredSelector({
    tokens: selectors.token.selectTokenList,
    tokenLoading: selectors.token.selectTokenLoading,
    currentTokenError: selectors.token.selectTokenCurrentError,
    currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
    setCurrentToken: token => dispatch(actions.token.setCurrentToken(token)),
    deleteToken: id => dispatch(actions.token.deleteToken(id)),
    clearCurrentTokenError: () => dispatch(actions.token.clearCurrentError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TokenDevices)
