import type from './article.types'
import * as utils from './article.utils'

export const initialState = {
  currentArticle: null,
  list: {
    data: [],
    body: [],
    limit: 50,
    page: 1,
    total: 1,
  },
  errors: {
    current: {},
    data: [],
  },
  isLoading: null,
  isDelete: false
}

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.ARTICLE_TRY:
      return {
        ...state,
        isLoading: true,
      }

    case type.SET_CURRENT_ARTICLE: {
      const currentArticle = action.payload

      return {
        ...state,
        currentArticle,
        isLoading: false,
      }
    }

    case type.ARTICLES_SOURCE_SUCCESS:
      return {
        ...state,
        sourcesArticles: action.payload,
      }

    case type.LIST_SUCCESS:
      const list = utils.formatData(action.payload)

      return { ...state, isLoading: false, list }

    case type.ADD_ARTICLE: {
      const currentArticle = action.payload
      const list = utils.addArticle(currentArticle, state.list)

      return {
        ...state,
        isLoading: false,
        list: {
          ...list,
        },
      }
    }

    case type.UPDATE_ARTICLE:
      const updateArticle = utils.updateArticle(
        state.list,
        state.errors,
        action
      )
      return { ...state, ...updateArticle, isLoading: false }

    case type.DELETE_ARTICLE:
        // let newArticleData = state.list.data
        // let newArticleBody = state.list.body
        // action.payload.forEach(articleId => {
        //     newArticleData = newArticleData.filter(
        //         article => article.id !== articleId
        //     )
        //     newArticleBody = newArticleBody.filter(
        //         article => article.id !== articleId
        //     )
        // })
        return {
          ...state,
          isDelete: action.payload,
          //list: { ...state.list, body: newArticleBody, data: newArticleData}
        }

    case type.ARTICLE_FAILED:
      const errors = utils.addError(state.errors, action)
      return { ...state, errors: { ...errors }, isLoading: false }

    case type.CLEAR_CURRENT_ERROR_ARTICLE: {
      return {
        ...state,
        errors: {
          ...state.errors,
          current: {},
        },
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default articleReducer
