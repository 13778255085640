import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import logo from './threader-logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: `url(${logo})`,
    backgroundSize: '8rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function Loading({ styles }) {
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ ...styles }}>
      <LinearProgress valueBuffer={80} color='primary' />
    </div>
  )
}
