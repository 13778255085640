// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import localStorage from 'redux-persist/es/storage'


export default class API {
  constructor (baseURL = process.env.REACT_APP_URL_API) {
    this.baseURL = baseURL
    this.api = this.create()
    this.generateApi = () => this.update()
  }

  create () {
    const api = apisauce.create({
      // base URL is read from the "constructor"
      baseURL: this.baseURL,
      // here are some default headers
      headers: {
        Accept: 'application/json',
      },
      // 10 second timeout...
      timeout: 600000,
    })
    // api from apisauce
    return api
  }

  update(){
    this.api.headers.Authorization = 'Bearer '+ window.localStorage.user
  }

  getStatus() {
    return this.api.get('/status')
  }
}




