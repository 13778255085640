import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import TableGrid from '../../components/table.grid/table.grid.component'
import {actions, selectors, user} from '../../state/modules'
import { useNavigate } from 'react-router-dom'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'

function Cluster({
                clusters,
                setCurrentCluster,
                clusterLoading,
                deleteCluster,
                currentClusterError,
                clearCurrentClusterError,
                currentUserAuth
                }) {
    const pageName = 'Cluster'
    const navigate = useNavigate()
    const [modal, setModal] = useState({ ...defaultDataModal })
    const rolUser = currentUserAuth?.rol > -1 ? currentUserAuth.rol : 3

    useEffect(() => {
        if (rolUser != 0) {
            return navigate(`/`)
        }
    },[])

    const handleClickAddNew = () => {
        navigate(`/${pageName.toLowerCase()}/detail/null`)
    }
    const onEditHandler = row => {
        const currentCluster = clusters.data.find(cluster => cluster.id === row.id)
        if (currentCluster) {
            setCurrentCluster(currentCluster)
            return navigate(`/${pageName.toLowerCase()}/detail/${row.rowId}`)
        }
        return alert(`${row.id} don't exist`)
    }

    const onDeleteHandler = row => {
        setModal({
            open: true,
            title: 'Notice!',
            description: `"${row.col1} #${row.rowId} " will be delete`,
            type: 'delete',
            id: row.rowId,
        })
    }

    const handleCloseModal = () => {
        setModal({ ...defaultDataModal, open: false })
    }

    const handleAccept = id => {
        if (modal.type === 'delete') {
            deleteCluster(id)
        } else if (modal.type === 'error') {
            setModal({ ...defaultDataModal, open: false })
            clearCurrentClusterError()
        }
    }

    useEffect(() => {
        if (currentClusterError.name) {
            setModal({
                open: true,
                title: 'Error!',
                description: `${currentClusterError.error}`,
                type: 'error',
                id: null,
            })
        }
    }, [currentClusterError.name])

    return (
        <Layout>
            <TableGrid
                name={pageName}
                rows={clusters.body}
                columns={clusters.header}
                loading={clusterLoading}
                limit={25}
                showAddBtn={false}
                onEdit={onEditHandler}
                onDelete={onDeleteHandler}
                onClickAddNew={handleClickAddNew}
                rolUser={rolUser}
            />
            <ServerModal
                isOpen={modal.open}
                title={modal.title}
                description={modal.description}
                id={modal.id}
                showBtns
                onAccept={handleAccept}
                onClose={handleCloseModal}
            />
        </Layout>
    )
}

const mapStateToProps = createStructuredSelector({
    clusters: selectors.cluster.selectClusterList,
    clusterLoading: selectors.cluster.selectClusterLoading,
    currentClusterError: selectors.cluster.selectClusterCurrentError,
    currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
    setCurrentCluster: cluster => dispatch(actions.cluster.setCurrentCluster(cluster)),
    deleteCluster: id => dispatch(actions.cluster.deleteCluster(id)),
    clearCurrentClusterError: () => dispatch(actions.cluster.clearCurrentError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cluster)
