import type from './weather.types'

export const setCurrentWeather = weather => ({
  type: type.SET_CURRENT_WEATHER_REQUESTED,
  payload: weather,
})

export const setListWeather = weathers => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: weathers,
})

export const addWeather = weather => ({
  type: type.ADD_WEATHER_REQUESTED,
  payload: weather,
})

export const updateWeather = weather => ({
  type: type.UPDATE_WEATHER_REQUESTED,
  payload: weather,
})

export const deleteWeather = weather_id => ({
  type: type.DELETE_WEATHER_REQUESTED,
  payload: weather_id,
})

export const weatherFailed = error => ({
  type: type.WEATHER_FAILED,
  payload: error,
})
