export const NAME = 'token' // same as the  global state

const types = {
  TOKEN_TRY: `[${NAME}]/TOKEN_TRY`,

  SET_CURRENT_TOKEN: `[${NAME}]/SET_CURRENT_TOKEN`,
  SET_CURRENT_TOKEN_REQUESTED: `[${NAME}]/SET_CURRENT_TOKEN_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  DELETE_TOKEN: `[${NAME}]/DELETE_TOKEN`,
  DELETE_TOKEN_REQUESTED: `[${NAME}]/DELETE_TOKEN_REQUESTED`,

  TOKEN_FAILED: `[${NAME}]/TOKEN_FAILED`,
  CLEAR_CURRENT_ERROR_TOKEN: `[${NAME}]/CLEAR_CURRENT_ERROR_TOKEN`,
}

export default types
