import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  Button,
  CardMedia,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import Layout from '../../components/layout/layout.component'
import Multiselect from '../../components/multiselect/multiselect.component'
import Tags from '../../components/tags/tags.component'
import Datetime from '../../components/datetime/datetime.component'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {actions, selectors, user} from '../../state/modules'
import { useParams } from 'react-router-dom'
import noImage from './no-image.jpg'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { validURL, getParam, setObjParam } from './articles.utils'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'
import Loading from '../../components/loading/loading.component'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& .MuiGrid-root': {
      marginLeft: 0,
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& button': {
      margin: theme.spacing(1),
    },
    '& textarea': {
      marginLeft: theme.spacing(0),
      width: '100%',
    },
  },
  container: {
    position: 'relative',
  },
  source: {
    width: 60,
    height: 60,
    position: 'absolute',
    left: 20,
    top: 20,
    backgroundColor: '#FFFFFFad',
    borderRadius: 5,
    backgroundSize: '80%',
  },
  cover: {
    width: '100%',
    height: 400,
    marginTop: '2rem',
  },
}))

function ArticlePageDetail({
  currentArticle,
  articlesLoading,
  locations,
  threads,
  sourcesList,
  addArticle,
  addArticleCrawler,
  currentArticleError,
  clearCurrenArticleError,
  currentUserAuth,
  getArticleById
}) {
  const classes = useStyles()
  const [size, setSize] = useState()
  const [modal, setModal] = useState({ ...defaultDataModal })
  const [crawlClicked, setCrawlClicked] = useState(false)
  const pageName = 'Article'

  //set data article
  const [articleData, setArticleData] = useState({
    link: '',
    headline: '',
    lead: '',
    dateFrom: moment().format(),
    last_update_datetime: moment().format(),
    fullText: '',
    internalTags: '',
    authors: '',
    source: {},
    mediaLink: '',
    currentLocations: [],
    currentThread: {},
    viewFullText: false,
    threads: [],
    statusId: 0,
  })

  const statusList = [
    { id: 0, name: 'Draft' },
    { id: 1, name: 'Revision' },
    { id: 2, name: 'Published' },
    { id: 3, name: 'Removed' },
  ]

  const { id } = useParams()
  const navigate = useNavigate()
  const rolUser = currentUserAuth?.rol > -1 ? currentUserAuth.rol : 3

  const handleSizeChange = size => {
    setSize(size)
  }

  useEffect(() => {
    getArticleById(id)
  },[])

  useEffect(() => {
    const paramThread = getParam('thread')

    if (paramThread) {
      setArticleData({ ...articleData, currentThread: paramThread })
    }

    if (currentArticle) {
      preProcessingArticleData(currentArticle, paramThread)
    }
  }, [currentArticle?.id > 0])

  const preProcessingArticleData = (article, currentThread = {}) => {
    const {
      full_text,
      internal_tags,
      date,
      published,
      authors,
      source,
      content,
      content: { link, headline, lead_paragraph, media, thread },
      status,
    } = article
    //console.log('article', article)

    const currentLocations = []

    content.locations.forEach(city => {
      let locationSetData = locations.data.filter(item =>
        item.name.includes(city)
      )
      if (locationSetData.length > 0)
        currentLocations.push({
          id: locationSetData[0].id,
          name: locationSetData[0].name,
        })
    })

    const currentThreadIndex = threads.data.findIndex(
      item => {
          if (thread[0]?.id){
            return item.name === thread[0]
          } else {
            return item.id === currentThread
          }
      }
    )

    let statusArticle = statusList.find(item => item.name == status)
    const sourceData = sourcesList.data.find(item => item.id === source.id)


    setArticleData({
      ...articleData,
      link,
      headline,
      lead: lead_paragraph,
      fullText: full_text,
      dateFrom: moment(date, 'YYYY-MM-DD').format(),
      last_update_datetime: moment(published, 'YYYY-MM-DD').format(),
      currentLocations,
      internalTags: internal_tags,
      authors: authors.toString(),
      source: sourceData,
      mediaLink: media.link,
      statusId: statusArticle?.id,
      threads: thread.length > 0 ? thread.map(item => {
        const thredIndex = threads.data.findIndex(th => th.name === item)
        return threads.data[thredIndex]
      }) : [threads.data[currentThreadIndex]],
      currentThread: threads.data[currentThreadIndex],
    })
  }

  const handleConvertUrl = (url) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(url);
      const base64 = btoa(String.fromCharCode(...new Uint8Array(data)));
      return base64;
  }
  
  const handleCrawlFromLinks = async () => {
    if (!validURL(articleData.link)) {
      alert('Please check link from article, this link is not validate')
      return
    }
  
    try {
      const base64ArticleUrl = await handleConvertUrl(articleData.link);
      const thread = threads.data.filter( item => item.id == articleData.currentThread)
  
      let linkData = {
        link: base64ArticleUrl,
        main_tag: thread[0].name || "default",
      }
  
      setCrawlClicked(true)
      setModal({
        open: true,
        title: 'Notice!',
        description: `"${articleData.link}" will be crawl`,
        type: 'crawl',
        data: linkData,
        id,
      });
    } catch (error) {
      console.error(error);
      alert('Error al convertir la URL en Base64');

    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!validURL(articleData.link)) {
      alert('Please check link from article, this link is not validate')
      return
    }

    let locationItems = articleData.currentLocations.map(item => {
      let itemData = locations.data.filter(data => data.id === item.id)

      if (itemData.length > 0) {
        itemData = itemData[0]
        return {
          city: itemData.city,
          country: itemData.country,
          longitude: itemData.longitude,
          latitude: itemData.latitude,
        }
      }
    })

    let dateData = new Date()

    if (articleData.dateFrom.length > 0)
      dateData = new Date(articleData.dateFrom)

    let lastUpdateDatetime = new Date()

    if (articleData.last_update_datetime.length > 0)
    lastUpdateDatetime = new Date(articleData.last_update_datetime)

    const article = {
      headline: articleData.headline,
      lead_paragraph: articleData.lead,
      media_type: 'image',
      media_link: articleData.mediaLink,
      link: articleData.link,
      datetime: dateData.toISOString(),
      last_update_datetime: lastUpdateDatetime.toISOString(),
      internal_tags: articleData.internalTags,
      main_tag: articleData.currentThread.name,
      authors: articleData.authors,
      article_source_id: Number(articleData.source.id),
      meta: {},
      status:
        statusList.find(status => status.id === articleData?.statusId)?.name ||
        'Draft',
      locations: [...locationItems],
      threads: articleData.threads.map(item => item),
      full_text: articleData.fullText,
    }

    const type = id === 'null' ? 'add' : 'update'

    setModal({
      open: true,
      title: 'Notice!',
      description: `"${articleData.headline}" will be ${type}`,
      type: 'add',
      data: article,
      id,
    })
  }

  const handleClickBack = ({ pathname }) => {
    navigate(
      `/${pathname[0]}/?thread=${setObjParam(articleData.currentThread)}`
    )
  }

  const handleCloseModal = () => {
    setModal({ ...defaultDataModal, open: false })
  }

  const handleAccept = () => {
    switch (modal.type) {
      case 'add':
        addArticle(modal.data)
        break
      case 'crawl':
        return addArticleCrawler(modal.data)
      case 'error':
        setModal({ ...defaultDataModal, open: false })
        setCrawlClicked(false)
        return clearCurrenArticleError()
    }
    navigate(
      `/${pageName.toLowerCase()}/?thread=${setObjParam(
        articleData.currentThread
      )}`
    )
  }

  useEffect(() => {
    if (currentArticleError.name) {
      setModal({
        open: true,
        title: 'Error!',
        description: `${currentArticleError.error}`,
        type: 'error',
        id: null,
      })
    }
  }, [currentArticleError.name])

  const handleImageError = (e) => {
      e.target.onerror = null;
      // e.target.style.display = 'none'
      e.target.src = noImage
  }

  return (
    <Layout
      hideBreadcrum
      showBackBtn
      onClickBack={handleClickBack}
      onSizeChange={handleSizeChange}
    >
      {articlesLoading && <Loading styles={{ height: '22vh' }} />}
      <form
        className={classes.root}
        style={{ width: size === 'xl' || size === 'lg' ? '85%' : '100%' }}
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='flex-end'
        >
          <Grid item xs={10}>
            <TextField
              required
              id='standard-basic-link'
              placeholder='(Link empty)'
              label='Link'
              value={articleData.link}
              disabled={id > 0 ? true : false}
              onInput={e =>
                setArticleData({ ...articleData, link: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Typography align='right'>
              <Button
                size='large'
                variant='contained'
                disableElevation
                color='primary'
                onClick={handleCrawlFromLinks}
                disabled={
                  articleData.link.length > 0
                    ? id === 'null'
                      ? crawlClicked
                      : true
                    : true
                }
              >
                CRAWL LINK
              </Button>
            </Typography>
          </Grid>
        </Grid>

        <TextField
          required={ articleData.source?.name ?  articleData.source.name !== 'Twitter' : true}
          id='standard-basic-headline'
          label='Headline'
          placeholder='(Headline content empty)'
          value={articleData.headline}
          onInput={e =>
            setArticleData({ ...articleData, headline: e.target.value })
          }
          disabled={rolUser < 2 ? false : true}
        />

        <TextField
          required
          id='standard-multiline-static'
          label='Lead Paragraph'
          placeholder='(Lead paragraph empty)'
          multiline
          value={articleData.lead}
          onInput={e =>
            setArticleData({ ...articleData, lead: e.target.value })
          }
          disabled={rolUser < 2 ? false : true}
        />

        <TextField
          id='standard-multiline-static'
          label='Article Full Text'
          placeholder='(Full content of the article)'
          multiline
          rows={articleData.viewFullText ? null : 2}
          value={articleData.fullText}
          onInput={e =>
            setArticleData({ ...articleData, fullText: e.target.value })
          }
          onDoubleClick={() => {
            setArticleData({
              ...articleData,
              viewFullText: !articleData.viewFullText,
            })
          }}
          disabled={rolUser < 2 ? false : true}
        />

        <TextField
          id='standard-basic-headline'
          label='Internal Tags'
          placeholder='(Internal Tags)'
          value={articleData.internalTags}
          onInput={e =>
            setArticleData({ ...articleData, internalTags: e.target.value })
          }
          disabled={rolUser < 2 ? false : true}
        />

        <TextField
          required
          id='standard-basic-url'
          label='Media URL'
          placeholder='(https://image.empty)'
          value={articleData.mediaLink}
          onInput={e =>
            setArticleData({ ...articleData, mediaLink: e.target.value })
          }
          disabled={rolUser < 2 ? false : true}
        />

        <div className={classes.container}>
          {articleData?.source && (
            <CardMedia
              className={classes.source}
              image={articleData.source.logo}
              title={articleData.source.name}
              component='div'
            />
          )}

          <CardMedia
            className={classes.cover}
            image={
              articleData?.mediaLink?.length > 0
                ? articleData.mediaLink
                : noImage
            }
            title={
              articleData?.headline?.length > 0
                ? articleData.headline
                : '(No name)'
            }
            component='img'
            onError={handleImageError}
          />
        </div>

        <Datetime
          labelDate={"Date picker dialog"}
          labelTime={"Time picker"}
          date={articleData.dateFrom}
          setDate={date => setArticleData({ ...articleData, dateFrom: date })}
          disabled={rolUser < 2 ? false : true}
        />

        <Datetime
          labelDate={"Last Date published"}
          labelTime={"Time picker"}
          date={articleData.last_update_datetime}
          setDate={date => setArticleData({ ...articleData, dateFrom: date })}
          disabled={rolUser < 2 ? false : true}
        />

        <TextField
          required
          id='authors'
          label='Authors'
          placeholder='authors'
          value={articleData.authors}
          onInput={e =>
            setArticleData({ ...articleData, authors: e.target.value })
          }
          disabled={rolUser < 2 ? false : true}
        />

        <Tags
          required
          label='Locations'
          placeholder='Locations'
          defaultValue={articleData.currentLocations}
          options={locations.data}
          onChange={data =>
            setArticleData({ ...articleData, currentLocations: data })
          }
          disabled={rolUser < 2 ? false : true}
        />

        <Multiselect
          required={true}
          name='Source'
          dataSet={sourcesList.data}
          multiple={false}
          defaultData={
            () => {
              const index = sourcesList.data.findIndex(
                source => source.id === articleData?.source?.id
              )
              return index > -1 ? index : 1
            }
          }
          setCurrentItems={id => {
            const source = sourcesList.data.find(ele => ele.id === id)
            if (source) {
              setArticleData({
                ...articleData,
                source,
              })
            }
          }}
          disabled={rolUser < 2 ? false : true}
        />

        <Tags
          required
          label='Threads'
          placeholder='Thread name'
          defaultValue={articleData.threads}
          options={threads.data.filter(item => item.status)}
          onChange={data => setArticleData({ ...articleData, threads: data })}
          disabled={rolUser < 2 ? false : true}
        />

        <Multiselect
          required
          name='Status'
          dataSet={statusList}
          multiple={false}
          defaultData={() => {
            const index = statusList.findIndex(
              status => status.id === articleData?.statusId
            )
            return index > -1 ? index : 1
          }}
          setCurrentItems={id => {
            setArticleData({ ...articleData, statusId: id })
          }}
          disabled={rolUser < 2 ? false : true}
        />

        <Box m={5} />

          {rolUser < 2 &&
          <Grid container justify='flex-end'>
              <Button
                  size='large'
                  variant='contained'
                  disableElevation
                  color='primary'
                  type='submit'
              >
                  Save
              </Button>
          </Grid>
          }

        <ServerModal
          isOpen={modal.open}
          title={modal.title}
          description={modal.description}
          id={modal.id}
          showBtns
          onAccept={handleAccept}
          onClose={handleCloseModal}
        />
      </form>
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  articles: selectors.article.selectArticleList,
  sourcesList: selectors.source.selectSourceList,
  currentArticle: selectors.article.selectCurrentArticle,
  articlesLoading: selectors.article.selectArticleLoading,
  threads: selectors.thread.selectThreadList,
  locations: selectors.location.selectLocationList,
  currentArticleError: selectors.article.selectArticleCurrentError,
  currentUserAuth: user.selectors.selectCurrentUserAuth,
})

const mapDispatchToProps = dispatch => ({
  getArticleById: id => {
    dispatch(actions.article.getArticleById({ id })) 
  },
  setCurrentArticle: article =>
    dispatch(actions.article.setCurrentArticle(article)),
  addArticle: article => dispatch(actions.article.addArticle(article)),
  addArticleCrawler: linkData =>
    dispatch(actions.article.addArticleCrawler(linkData)),
  clearCurrenArticleError: () => dispatch(actions.article.clearCurrentError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePageDetail)
