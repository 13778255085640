export const NAME = 'weather' // same as the  global state

const types = {
  WEATHER_TRY: `[${NAME}]/WEATHER_TRY`,

  SET_CURRENT_WEATHER: `[${NAME}]/SET_CURRENT_WEATHER`,
  SET_CURRENT_WEATHER_REQUESTED: `[${NAME}]/SET_CURRENT_WEATHER_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_WEATHER: `[${NAME}]/ADD_WEATHER`,
  ADD_WEATHER_REQUESTED: `[${NAME}]/ADD_WEATHER_REQUESTED`,

  UPDATE_WEATHER: `[${NAME}]/UPDATE_WEATHER`,
  UPDATE_WEATHER_REQUESTED: `[${NAME}]/UPDATE_WEATHER_REQUESTED`,

  DELETE_WEATHER: `[${NAME}]/DELETE_WEATHER`,
  DELETE_WEATHER_REQUESTED: `[${NAME}]/DELETE_WEATHER_REQUESTED`,

  WEATHER_FAILED: `[${NAME}]/WEATHER_FAILED`,
}

export default types
