export const NAME = 'cluster' // same as the  global state

const types = {
  CLUSTER_TRY: `[${NAME}]/CLUSTER_TRY`,

  SET_CURRENT_CLUSTER: `[${NAME}]/SET_CURRENT_CLUSTER`,
  SET_CURRENT_CLUSTER_REQUESTED: `[${NAME}]/SET_CURRENT_CLUSTER_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_CLUSTER: `[${NAME}]/ADD_CLUSTER`,
  ADD_CLUSTER_REQUESTED: `[${NAME}]/ADD_CLUSTER_REQUESTED`,

  UPDATE_CLUSTER: `[${NAME}]/UPDATE_CLUSTER`,
  UPDATE_CLUSTER_REQUESTED: `[${NAME}]/UPDATE_CLUSTER_REQUESTED`,

  DELETE_CLUSTER: `[${NAME}]/DELETE_CLUSTER`,
  DELETE_CLUSTER_REQUESTED: `[${NAME}]/DELETE_CLUSTER_REQUESTED`,
  DELETE_ARTICLE_CLUSTER_REQUESTED: `[${NAME}]/DELETE_ARTICLE_CLUSTER_REQUESTED`,

  CLUSTER_FAILED: `[${NAME}]/CLUSTER_FAILED`,
  CLEAR_CURRENT_ERROR_CLUSTER: `[${NAME}]/CLEAR_CURRENT_ERROR_CLUSTER`,
}

export default types
