export const addError = (errors, action) => {
  const errorId = errors.data.findIndex(
    error => error.name === action.payload.name
  )

  errors.current = action.payload

  if (errorId === -1) errors.data.push(action.payload)
  else errors.data[errorId] = action.payload

  return errors
}

export const formatData = payload => {
  const data = payload.data.map(row => {
    return {
      id: row?.id,
      rowId: row?.id,
      col1: row?.device_id,
      col2: row?.device_model,
      col3: row?.status
    }
  })

  const header = [
    { field: 'id', hide: true },
    { field: 'rowId', headerName: 'id', width: 80 },
    { field: 'col1', headerName: 'device id', width: 280 },
    { field: 'col2', headerName: 'device model', width: 100 },
    { field: 'col3', headerName: 'status', width: 100},
  ]

  const body = [...data]

  return {
    data: [...payload.data],
    header,
    body,
    limit: 50,
    page: 1,
    total: 1,
  }
}
