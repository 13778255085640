import {
  Grid,
  Button,
  makeStyles,
  TextField,
  CardMedia,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import { selectors, actions } from '../../state/modules'
import { isImageURL } from '../article/articles.utils'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& button': {
      margin: theme.spacing(1),
    },
  },
  cover: {
    width: '100%',
    height: 400,
    marginLeft: '.5rem',
    marginTop: '2rem',
  },
  source: {
    width: 60,
    height: 60,
  },
}))

function SourcePageDetail({
  currentSource,
  addSource,
  updateSource,
  setCurrentSource,
  deleteSource,
}) {
  const classes = useStyles()
  const [size, setSize] = useState()
  const pageName = 'Source'
  const [modal, setModal] = useState({ ...defaultDataModal })

  const navigate = useNavigate()

  const [source, setSource] = useState({
    logo: '',
    name: '',
    city: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    pattern: '',
  })

  const { id } = useParams()

  useEffect(() => {
    if (id !== 'null') setSource(currentSource)
  }, [id])

  const handleSizeChange = size => {
    setSize(size)
  }

  const handleDeleteSubmit = () => {
    setModal({
      open: true,
      title: 'Notice!',
      description: `"${source.name} " will be delete`,
      type: 'delete',
      id,
    })
  }

  const handleClickBack = ({ pathname }) => {
    setCurrentSource(null)
    navigate(`/${pathname[0]}`)
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (isImageURL(source.logo)) {
      const type = id === 'null' ? 'add' : 'update'
      setModal({
        open: true,
        title: 'Notice!',
        description: `"${source.name}" will be ${type}`,
        type,
        id,
      })
    } else {
      setModal({
        open: true,
        title: 'Warning!',
        description: `The image url is not valid`,
        type: 'message',
        id: null,
      })
    }
  }

  const handleCloseModal = () => {
    setModal({ ...defaultDataModal, open: false })
  }

  const handleAccept = id => {
    if (modal.type === 'delete') {
      deleteSource(id)
    } else if (modal.type === 'add') {
      addSource(source)
    } else if (modal.type === 'update') {
      updateSource({ id, data: source })
    } else if (modal.type === 'message') {
      return
    }
    navigate(`/${pageName.toLowerCase()}`)
  }

  return (
    <Layout
      hideBreadcrum
      showBackBtn
      onClickBack={handleClickBack}
      onSizeChange={handleSizeChange}
    >
      <form
        className={classes.root}
        style={{ width: size === 'xl' ? '60%' : '100%' }}
        //noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <TextField
          required
          id='name-source'
          label='name'
          placeholder='(Name)'
          value={source.name}
          onInput={e => setSource({ ...source, name: e.target.value })}
        />

        <TextField
          required
          id='logo-source'
          label='logo'
          placeholder='(Logo)'
          value={source.logo}
          onInput={e => setSource({ ...source, logo: e.target.value })}
        />

        {source.logo.length > 0 && (
          <CardMedia
            className={classes.source}
            image={source.logo}
            title={source.name}
          />
        )}

        <TextField
          id='city-source'
          label='city'
          placeholder='(City name)'
          value={source.city}
          onInput={e => setSource({ ...source, city: e.target.value })}
        />

        <TextField
          id='country-source'
          label='country'
          placeholder='(Country name)'
          value={source.country}
          onInput={e => setSource({ ...source, country: e.target.value })}
        />

        <TextField
          id='state-source'
          label='state'
          placeholder='(State name)'
          value={source.state}
          onInput={e => setSource({ ...source, state: e.target.value })}
        />

        <TextField
          id='state-latitude'
          type='number'
          label='Latitude'
          placeholder='44.0059728'
          value={source.latitude}
          onInput={e => setSource({ ...source, latitude: e.target.value })}
        />

        <TextField
          type='number'
          id='state-longitude'
          label='Longitude'
          placeholder='-74.0059728'
          value={source.longitude}
          onInput={e => setSource({ ...source, longitude: e.target.value })}
        />

        <TextField
          required
          id='state-pattern'
          label='Pattern (http.*\.bloomberg\.com/.*)'
          placeholder='http.*\.bloomberg\.com/.*'
          value={source.pattern}
          onInput={e => setSource({ ...source, pattern: e.target.value })}
        />

        <Grid container justify='flex-end'>
          {id !== 'null' && (
            <Button
              size='large'
              variant='outlined'
              onClick={handleDeleteSubmit}
            >
              Delete
            </Button>
          )}

          <Button
            size='large'
            variant='contained'
            disableElevation
            color='primary'
            type='submit'
          >
            Save
          </Button>
        </Grid>

        <ServerModal
          isOpen={modal.open}
          title={modal.title}
          description={modal.description}
          id={modal.id}
          showBtns
          onAccept={handleAccept}
          onClose={handleCloseModal}
        />
      </form>
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  currentSource: selectors.source.selectCurrentSource,
  sourceLoading: selectors.source.selectSourceLoading,
  //currentSourceError: selectors.source.selectSourceCurrentError,
})

const mapDispatchToProps = dispatch => ({
  setCurrentSource: source => dispatch(actions.source.setCurrentSource(source)),
  addSource: source => dispatch(actions.source.addSource(source)),
  deleteSource: id => dispatch(actions.source.deleteSource(id)),
  updateSource: source => dispatch(actions.source.updateSource(source)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SourcePageDetail)
