import type from './timeline.types'

export const setCurrentTimeline = timeline => ({
  type: type.SET_CURRENT_TIMELINE_REQUESTED,
  payload: timeline,
})

export const setListTimeline = timeline => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: timeline,
})

export const addTimeline = timeline => ({
  type: type.ADD_TIMELINE_REQUESTED,
  payload: timeline,
})

export const updateTimeline = timeline => ({
  type: type.UPDATE_TIMELINE_REQUESTED,
  payload: timeline,
})

export const deleteTimeline = timeline_id => ({
  type: type.DELETE_TIMELINE_REQUESTED,
  payload: timeline_id,
})

export const timelineFailed = error => ({
  type: type.TIMELINE_FAILED,
  payload: error,
})

export const clearCurrentError = () => ({
  type: type.CLEAR_CURRENT_ERROR_TIMELINE,
})
