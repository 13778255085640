export const NAME = 'article' // same as the  global state

const types = {
  ARTICLE_TRY: `[${NAME}]/ARTICLE_TRY`,

  ARTICLES_SOURCE_REQUESTED: `[${NAME}]/ARTICLES_SOURCE_REQUESTED`,
  ARTICLES_SOURCE_SUCCESS: `[${NAME}]/ARTICLES_SOURCE_SUCCESS`,
  GET_ARTICLE_BY_ID: `[${NAME}]/GET_ARTICLE_BY_ID`,

  SET_CURRENT_ARTICLE: `[${NAME}]/SET_CURRENT_ARTICLE`,
  SET_CURRENT_ARTICLE_REQUESTED: `[${NAME}]/SET_CURRENT_ARTICLE_REQUESTED`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  ADD_ARTICLE: `[${NAME}]/ADD_ARTICLE`,
  ADD_ARTICLE_REQUESTED: `[${NAME}]/ADD_ARTICLE_REQUESTED`,
  ADD_ARTICLE_REQUESTED_CRAWLER: `[${NAME}]/ADD_ARTICLE_REQUESTED_CRAWLER`,

  UPDATE_ARTICLE: `[${NAME}]/UPDATE_ARTICLE`,
  UPDATE_ARTICLE_REQUESTED: `[${NAME}]/UPDATE_ARTICLE_REQUESTED`,

  DELETE_ARTICLE: `[${NAME}]/DELETE_ARTICLE`,
  DELETE_ARTICLE_REQUESTED: `[${NAME}]/DELETE_ARTICLE_REQUESTED`,

  ARTICLE_FAILED: `[${NAME}]/ARTICLE_FAILED`,
  CLEAR_CURRENT_ERROR_ARTICLE: `[${NAME}]/CLEAR_CURRENT_ERROR_ARTICLE`,
}

export default types
