import reducer, { initialState } from './article.reducer'
import types from './article.types'

import * as sagas from './article.sagas'
import * as actions from './article.actions'
import * as utils from './article.utils'
import * as selectors from './article.selectors'

export const article = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
