import {
  Grid,
  Button,
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Layout from '../../components/layout/layout.component'
import { selectors, actions } from '../../state/modules'
import AddListItem from '../../components/addlistitem/addlistitem.component'
import ServerModal from '../../components/modal/modal.component'
import { defaultDataModal } from '../../components/modal/modal.utils'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& button': {
      margin: theme.spacing(1),
    },
  },
  cover: {
    width: '100%',
    height: 400,
    marginLeft: '.5rem',
    marginTop: '2rem',
  },
}))

function ThreadPageDetail({
  setCurrentThread,
  currentThread,
  addThread,
  updateThread,
  deleteThread,
}) {
  const classes = useStyles()
  const [size, setSize] = useState()
  const navigate = useNavigate()
  const [thread, setThread] = useState({
      name: '',
      status: false,
      is_crawler: false,
      keywords: [],
      tweet_sources: '',
      article_source: []
  })
  const { id } = useParams()
  const pageName = 'Thread'
  const [modal, setModal] = useState({ ...defaultDataModal })

  const handleSizeChange = size => {
    setSize(size)
  }

  useEffect(() => {
    if (id !== 'null') setThread(currentThread)
  }, [])

  const handleDeleteSubmit = () => {
    setModal({
      open: true,
      title: 'Notice!',
      description: `"${thread.name} " will be delete`,
      type: 'delete',
      id,
    })
  }

  const handleClickBack = ({ pathname }) => {
    setCurrentThread(null)
    navigate(`/${pathname[0]}`)
  }

  const handleSubmit = event => {
    event.preventDefault()

    const type = id === 'null' ? 'add' : 'update'
    setModal({
      open: true,
      title: 'Notice!',
      description: `"${thread.name} " will be ${type}`,
      type,
      id,
    })
  }

  const handleCloseModal = () => {
    setModal({ ...defaultDataModal, open: false })
  }

  const handleAccept = id => {
    //
    if (modal.type === 'delete') {
      deleteThread(id)
    } else if (modal.type === 'add') {
      addThread(thread)
    } else if (modal.type === 'update') {
      updateThread({ id, data: thread })
    } else if (modal.type === 'message') {
      return
    }

    navigate(`/${pageName.toLowerCase()}`)
  }

  return (
    <Layout
      hideBreadcrum
      showBackBtn
      onClickBack={handleClickBack}
      onSizeChange={handleSizeChange}
    >
      <form
        className={classes.root}
        style={{ width: size === 'xl' ? '60%' : '100%' }}
        //noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <TextField
          required
          id='thread-name'
          label='Title'
          placeholder='(Thread name)'
          value={thread.name}
          onInput={e => setThread({ ...thread, name: e.target.value })}
        />

        <Grid container>
          <FormControlLabel
            control={
              <Checkbox
                checked={thread.status}
                onChange={() => setThread({ ...thread, status: !thread.status })}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                color='primary'
                name='checkedF'
              />
            }
            label='Published'
          />

          <FormControlLabel
              control={
                  <Checkbox
                      checked={thread.crawl}
                      onChange={() => {
                        if (!thread.crawl){
                          setThread({ ...thread, crawl: !thread.crawl })
                          return
                        }else{
                          setThread({
                              ...thread,
                              crawl: !thread.crawl,
                              keywords: []
                          })
                        }

                      }}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color='primary'
                      name='checkedF'
                  />
              }
              label='Crawling'
          />
        </Grid>

        { thread.crawl && <AddListItem
            listItem={thread.keywords}
            onChange={(items) => setThread({ ...thread, keywords: [...items] })}
        /> }

        <Grid container justify='flex-end'>
          {id !== 'null' && (
            <Button
              size='large'
              variant='outlined'
              onClick={handleDeleteSubmit}
            >
              Delete
            </Button>
          )}

          <Button
            size='large'
            variant='contained'
            disableElevation
            color='primary'
            type='submit'
          >
            Save
          </Button>
        </Grid>

        <ServerModal
          isOpen={modal.open}
          title={modal.title}
          description={modal.description}
          id={modal.id}
          showBtns
          onAccept={handleAccept}
          onClose={handleCloseModal}
        />
      </form>
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  threads: selectors.thread.selectThreadList,
  currentThread: selectors.thread.selectCurrentThread,
  threadLoading: selectors.thread.selectThreadLoading,
  //currentThreadError: selectors.thread.selectThreadCurrentError,
})

const mapDispatchToProps = dispatch => ({
  setCurrentThread: thread => dispatch(actions.thread.setCurrentThread(thread)),
  addThread: thread => dispatch(actions.thread.addThread(thread)),
  updateThread: thread => dispatch(actions.thread.updateThread(thread)),
  deleteThread: threadId => dispatch(actions.thread.deleteThread(threadId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThreadPageDetail)
