import { all, put, takeLatest } from 'redux-saga/effects'
import type from './timeline.types'
import Service from '../../../services'

const Ser = Service()
const apiGet = new Ser.API_GET()
const URL = process.env.URL_API_JSON_API

export function* tryTimelineList() {
  try {
    //const response = yield call(service.apisauce.get, '/timeline')
    const response = yield apiGet.getTimeline(2)
    //const payload = yield call([response, 'json'])
    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.TIMELINE_FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchTimelineList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryTimelineList)
}

//console.log('parserAPI', parserAPI)

function* tryCurrentTimeline(action) {
  console.log('++++++++++ setCurrentTimeline timeline', action)
  //yield put({ type: type.TIMELINE_TRY })
  try {
    //const response = yield call(parserAPI.apisauce.get, '/timeline')
    //
    const response = yield apiGet.getTimeline(
      2,
      '2020-12-30',
      '2021-01-01',
      757
    )

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.SET_CURRENT_TIMELINE, payload })
  } catch (error) {
    yield put({
      type: type.TIMELINE_FAILED,
      payload: { name: type.SET_CURRENT_TIMELINE, error: error.message },
    })
  }
}

export function* watchCurrentTimeline() {
  yield takeLatest(type.SET_CURRENT_TIMELINE_REQUESTED, tryCurrentTimeline)
}

export function* addTimeline(action) {
  try {
    const response = yield fetch(`${URL}/timeline`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    })

    const newTimeline = yield response.json()

    yield put({
      type: type.ADD_TIMELINE,
      payload: newTimeline.data,
    })
  } catch (error) {
    yield put({
      type: type.TIMELINE_FAILED,
      payload: { name: type.ADD_TIMELINE, error: error.message },
    })
  }
}

export function* watchAddTimeline() {
  yield takeLatest(type.ADD_TIMELINE_REQUESTED, addTimeline)
}

export function* deleteTimeline(action) {
  yield put({ type: type.TIMELINE_TRY })
  try {
    console.log('++++++++++ delete timeline', action)
    const response = yield fetch(`${URL}/timeline/` + action.payload, {
      method: 'DELETE',
    })

    const deletedTimeline = yield response.json()

    yield put({
      type: type.DELETE_TIMELINE,
      //payload: action.payload,
      payload: deletedTimeline,
    })
  } catch (error) {
    yield put({
      type: type.TIMELINE_FAILED,
      payload: { name: type.DELETE_TIMELINE, error: error.message },
    })
  }
}

export function* watchDeleteTimeline() {
  yield takeLatest(type.DELETE_TIMELINE_REQUESTED, deleteTimeline)
}

export function* updateTimeline(action) {
  try {
    const response = yield fetch(`${URL}/timeline/` + action.payload.id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    })

    const updatedTimeline = yield response.json()

    yield put({
      type: type.UPDATE_TIMELINE,
      payload: updatedTimeline.data,
    })
  } catch (error) {
    yield put({
      type: type.TIMELINE_FAILED,
      payload: { name: type.UPDATE_TIMELINE, error: error.message },
    })
  }
}

export function* watchUpdateTimeline() {
  yield takeLatest(type.UPDATE_TIMELINE_REQUESTED, updateTimeline)
}

export function* timelineSagas() {
  yield all([
    //call(tryTimelineList),
    //call(tryCurrentTimeline),
    watchTimelineList(),
    watchCurrentTimeline(),
    watchAddTimeline(),
    watchDeleteTimeline(),
    watchUpdateTimeline(),
  ])
}
