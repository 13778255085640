import { createSelector } from 'reselect'
import { NAME } from './location.types'

export const selectLocation = state => state[NAME]

export const selectLocationLoading = createSelector(
  [selectLocation],
  location => location.isLoading
)

export const selectCurrentLocation = createSelector(
  [selectLocation],
  location => location.currentLocation
)

export const selectLocationList = createSelector(
  [selectLocation],
  location => location.list
)

export const selectLocationErrors = createSelector(
  [selectLocation],
  location => location.errors
)

export const selectLocationCurrentError = createSelector(
  [selectLocationErrors],
  error => error.current
)
